import React from 'react';
import { IconProps } from './types';
import classes from './styledIcons.module.css';
import clsx from 'clsx';

export const TextIcon = (props: IconProps) => {
    const wrapperContainerClass = props.currentActive ? classes.iconBorderActive : classes.iconBorderInactive;
    const wrapperClass = props.isDone ? classes.active : classes.inactive;
    const iconBGFillClass = props.isDone ? classes.iconBGActive : classes.iconBGInactive;
    const iconFillClass = props.isDone ? classes.iconColorActive : classes.iconColorInactive;

    return (
        <div className={clsx(classes.wrapperContainer, wrapperContainerClass)}>
            <div className={clsx(classes.wrapper, wrapperClass)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" className={iconBGFillClass} />
                    <mask id="mask0_7005_1524" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
                        <rect x="4" y="4" width="24" height="24" fill="white" />
                    </mask>
                    <g mask="url(#mask0_7005_1524)">
                        <path
                            d="M15 26V20H17V22H25V24H17V26H15ZM7 24V22H13V24H7ZM10.425 18H12.5L13.6 14.925H18.425L19.5 18H21.575L17.075 6H14.925L10.425 18ZM14.2 13.2L15.95 8.225H16.05L17.8 13.2H14.2Z"
                            className={iconFillClass}
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};
