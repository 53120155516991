import { useEffect, useState } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import { Button } from '../../components/Button';
import { fetchMeanValue } from '../../service/MicService';
import { Text } from '../../components/Text';
import { changeSilenceValue } from '../../redux/appConfigSlice';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';

const SilenceCheckScreen = (props: any) => {
    const [buttonLabel, setButtonLabel] = useState(5);
    const [isSilenceFinished, setIsSilenceFinished] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    let audioRecorder = useAudioRecorder({
        ...AudioRecorderDefaultConfig,
        deviceId: props?.selectedMic || 'default',
    });

    useEffect(() => {
        if (audioRecorder.recordingBlob && !audioRecorder.isRecording) {
            fetchMeanValue(audioRecorder.recordingBlob).then((mValue: any) => {
                dispatch(changeSilenceValue(mValue));
                finishPage();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder.isRecording, audioRecorder.recordingBlob, props]);

    useEffect(() => {
        if (isSilenceFinished) {
            audioRecorder?.stopRecording();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSilenceFinished, props]);

    const stop = async () => {
        audioRecorder.stopRecording();
    };

    useEffect(() => {
        if (props.prevButtonClicked) {
            stop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prevButtonClicked]);

    const finishPage = () => {
        setTimeout(() => props?.setIsTimerFinished(true), 1);
    };

    /* Start the timer */
    const startCounter = async () => {
        audioRecorder.startRecording(); // Start recording before timer starts
        let interval = setInterval(() => {
            setButtonLabel((currLabel) => {
                if (currLabel !== 1) {
                    return currLabel - 1;
                } else {
                    setIsSilenceFinished(true);
                    clearInterval(interval);
                }
                return 0;
            });
        }, 1000);
    };

    return (
        <div>
            <p>
                <Text text="We need to check the sound in the room." />
            </p>
            <Text text="Click <span class='bolderString'>Start</span>, then stay quiet for 5 seconds." />
            <p>
                <Text text="Please don't say anything during this time." />
            </p>
            <div className="buttonBlk">
                <Button disabled={!props.selectedMic} size="lg" onClick={buttonLabel === 5 ? startCounter : () => {}}>
                    {buttonLabel === 5 ? 'Start' : buttonLabel}
                </Button>
            </div>
        </div>
    );
};

export default SilenceCheckScreen;
