import * as React from 'react';
import type { DropdownItemProps, DropdownProps } from './Dropdown.types';
import classes from './Dropdown.module.css';
import { DropdownInput } from '../Input';
import { ChevronDownIcon, CloseIcon } from '../../assets/icons';
import { isEnter, isEsc, isSpaceBar } from '../ComponentUtils/keyboardUtils';
import clsx from 'clsx';

const getDropdownLabel = (children: React.ReactElement<DropdownItemProps>[], value: string) => {
    return children.find((child) => child.props.value === value)?.props.label;
};

export const Dropdown = React.memo((props: DropdownProps) => {
    const { label, helperText, hintText, value, placeholder } = props;
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [dropdownValue, setDropdownValue] = React.useState<string>(getDropdownLabel(props.children, value) ?? '');

    // Event Listener to close the dropdown when user click outside the component
    React.useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        if (value !== '') {
            setDropdownValue(getDropdownLabel(props.children, value) ?? '');
        }
    }, [value]);

    const onOpenPopup = () => {
        setIsOpen((prevState) => !prevState);
    };

    // keyboard navigation handler
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (isEnter(e) || isSpaceBar(e)) {
            setIsOpen(true);
        }

        if (isEsc(e)) {
            setIsOpen(false);
        }
    };

    const onSelect = (ddVal: string) => {
        setDropdownValue(getDropdownLabel(props.children, ddVal) ?? '');
        setIsOpen(false);
        props.onSelect(value);
    };

    return (
        <div className={classes.root} ref={wrapperRef} data-testid="dropdown-component">
            <DropdownInput
                value={dropdownValue}
                onChange={() => {}}
                type="dropdown"
                label={label}
                helperText={helperText}
                hintText={hintText}
                onClick={onOpenPopup}
                placeholder={placeholder}
                icon={
                    isOpen ? <CloseIcon onClick={() => setIsOpen(false)} /> : <ChevronDownIcon onClick={() => setIsOpen(true)} />
                }
                readonly
                active={isOpen}
                onKeyDown={onKeyDown}
                inputClassName={clsx(classes['dropdown-input'], {
                    [classes['dropdown-active-state']]: isOpen,
                })}
            >
                {isOpen ? (
                    <div
                        data-testid="dropdown-popup"
                        className={classes['dropdown-popup']}
                        style={props.children.length > 5 ? { maxHeight: '200px', overflowY: 'scroll' } : undefined}
                    >
                        {props.children.map((item, index) => (
                            <item.type
                                value={item.props.value}
                                label={item.props.label}
                                onSelect={onSelect}
                                key={item.props.label + index}
                                isLast={props.children.length - 1 === index}
                                index={index}
                            />
                        ))}
                    </div>
                ) : undefined}
            </DropdownInput>
        </div>
    );
});

Dropdown.displayName = 'Dropdown';
