import type { StepperProps } from './Stepper.types';
import classes from './Stepper.module.css';

export const Stepper = (props: StepperProps) => {
    return (
        <div className={classes.root} style={{ maxWidth: props.width ? props.width : undefined }} data-testid="stepper-wrapper-test-id">
            {props.children
                ? props.children.map((element, index) => {
                      return (
                          <element.type
                              key={element.key}
                              {...element.props}
                              isLast={props.children.length - 1 === index}
                              isDone={props.currentActive >= index}
                              active={props.currentActive === index}
                              isNextActive={props.currentActive >= index + 1}
                          />
                      );
                  })
                : null}
        </div>
    );
};

Stepper.displayName = 'Stepper';
