import { SVGAttributes } from 'react';

export const TimerClockIcon = ({ className }: SVGAttributes<SVGSVGElement>) => (
  <svg 
    className={className}
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4900_1700)">
      <path 
        d="M5.85514 2.14355C6.2421 1.94242 6.27948 1.40631 5.92902 1.14676C5.37626 0.737383 4.69818 0.5 3.95363 0.5C2.09167 0.5 0.590088 2.00158 0.590088 3.84352C0.590088 4.551 0.811985 5.20301 1.18667 5.743C1.43335 6.09853 1.96778 6.07497 2.18106 5.69845C3.03536 4.19037 4.31603 2.94353 5.85514 2.14355Z" 
        fill="currentColor"
      />
      <path 
        d="M16.0464 0.5C15.3031 0.5 14.626 0.746052 14.0738 1.15357C13.7237 1.41192 13.76 1.94689 14.1452 2.1493C15.6848 2.95845 16.9657 4.19704 17.8199 5.71467C18.0325 6.09231 18.5683 6.11679 18.8151 5.76053C19.1887 5.22102 19.4099 4.56993 19.4099 3.86354C19.4099 2.00158 17.8883 0.5 16.0464 0.5Z" 
        fill="currentColor"
      />
      <path 
        d="M15.4461 15.416C16.7881 14.034 17.6281 12.152 17.6281 10.09C17.6281 5.886 14.2041 2.462 10.0001 2.462C5.79607 2.462 2.37207 5.886 2.37207 10.09C2.37207 12.172 3.21209 14.054 4.55408 15.416L3.05208 18.258C2.83206 18.68 2.99207 19.18 3.41409 19.4C3.53406 19.46 3.67407 19.5 3.81409 19.5C4.11409 19.5 4.41409 19.34 4.57407 19.04L5.91609 16.496C7.09607 17.258 8.51809 17.698 10.0201 17.698C11.5421 17.698 12.9441 17.258 14.1241 16.496L15.4661 19.04C15.6261 19.34 15.9261 19.5 16.2261 19.5C16.3661 19.5 16.5061 19.46 16.6261 19.4C17.0481 19.18 17.2081 18.66 16.9881 18.258L15.4461 15.416ZM12.7021 10.952H9.98008C9.50007 10.952 9.12007 10.55 9.12007 10.09V6.886C9.12007 6.40599 9.50007 6.026 9.98008 6.026C10.4601 6.026 10.8401 6.426 10.8401 6.886V9.25H12.7221C13.2041 9.25 13.5641 9.61 13.5641 10.09C13.5641 10.57 13.1641 10.952 12.7021 10.952Z" 
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4900_1700">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

TimerClockIcon.displayName = 'Outline__TimerClockIcon';
