import React from 'react';
import { IconProps } from './types';
import classes from './styledIcons.module.css';
import clsx from 'clsx';

export const VideoIcon = (props: IconProps) => {
    const wrapperContainerClass = props.currentActive ? classes.iconBorderActive : classes.iconBorderInactive;
    const wrapperClass = props.isDone ? classes.active : classes.inactive;
    const iconBGFillClass = props.isDone ? classes.iconBGActive : classes.iconBGInactive;
    const iconFillClass = props.isDone ? classes.iconColorActive : classes.iconColorInactive;

    return (
        <div className={clsx(classes.wrapperContainer, wrapperContainerClass)}>
            <div className={clsx(classes.wrapper, wrapperClass)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" className={iconBGFillClass} />
                    <mask id="mask0_7001_6099" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
                        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7001_6099)">
                        <path
                            d="M6 24V8H26V24H6ZM8 22H24V10H8V22ZM10 20H18V18H10V20ZM20 20H22V18H20V20ZM10 16H12V14H10V16ZM14 16H22V14H14V16Z"
                            className={iconFillClass}
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};
