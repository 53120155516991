export const isEnter = (e: React.KeyboardEvent) => {
    return e.code === 'Enter'
}

export const isSpaceBar = (e: React.KeyboardEvent) => {
    return e.code === 'Space'
}

export const isBackSpace = (e: React.KeyboardEvent) => {
    return e.code === 'Backspace'
}

export const isEsc = (e: React.KeyboardEvent) => {
    return e.code === 'Escape'
}