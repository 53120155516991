import { useEffect, useRef, useState } from "react";
import { Card } from "../../components/Card";
import './SummaryScreen.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Image from '../../components/image/Image';
import { ReactComponent as PlayButtonBoW } from '../../assets/icons/PlayButtonBoW.svg';
import { ReactComponent as PlayButtonYoB } from '../../assets/icons/PlayButtonYoB.svg';
import { ReactComponent as PlayButtonWoB } from '../../assets/icons/PlayButtonWoB.svg';
import TimerProgressBar from "../../components/timerProgressBar/TimerProgressBar";
import { ReactComponent as Error } from '../../assets/icons/Error.svg';
import { fetchHealthCheck } from "../../api/authApis";
import { appThemeNames } from "../../constants/constants";
import { Text } from '../../components/Text';
const videoPath = require("../../assets/video/SpeakingTestReadinessVideo.mp4");

// Summary screen component that displays the summary of the selected options.
const SummaryScreen = (props: any) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [screenState, setScreenState] = useState('NotStarted');
  const { appTheme } = useSelector((state: RootState) => state.appConfigSlice);
  const [apiUpStatus, setApiUpStatus] = useState<boolean>(false);
  const [videoHeight, setVideoHeight] = useState(380);
  const [videoWidth, setVideoWidth] = useState(220);

  /**
   * Code to set a state variable for video duration of 'videoPath'.
   * Default value of videoDuration is 112 seconds, which is the length of the 'videoPath' video.
   * If this video changes then update the default value of videoDuration with new video duration.
   */
  const [videoDuration, setVideoDuration] = useState(112);

  useEffect(() => {
    if (!props.isVideoPlayed) {
      props.setNextButtonDisabled(true);
      props.setPrevButtonDisabled(true);
    }

    fetchHealthCheck().then((response) => {
      if (response && !response.atc && !response.service) {
        return setApiUpStatus(false);
      }
      return setApiUpStatus(true);
    }).catch(() => {
      setApiUpStatus(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let PlayButtonSvg;
  if (appTheme === appThemeNames.BLACK_ON_WHITE) {
    PlayButtonSvg = PlayButtonWoB;
  } else if (appTheme === appThemeNames.WHITE_ON_BLACK) {
    PlayButtonSvg = PlayButtonBoW;
  } else if (appTheme === appThemeNames.YELLOW_ON_BLACK) {
    PlayButtonSvg = PlayButtonYoB;
  }

  /** 
   * Below code set the height and width of the video when window resizes
   * Setting the height and width in percentage(eg. 0.50 is 50%) calculated based on screeen height and width 
  */
  useEffect(() => {
    const handleResize = () => {
      let tempHeight = window.innerHeight * (window.innerHeight > window.innerWidth ? 0.25 : 0.50);
      let tempWidth = window.innerWidth * (window.innerHeight > window.innerWidth ? 0.50 : 0.42);
      setVideoHeight(tempHeight);
      setVideoWidth(tempWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`flex summaryAvatarContainer`}>
      <Card className="contentGrow" >
        <div className="container jc">
            <div>
              {screenState === 'NotStarted' && !props?.isVideoPlayed && (
                <div>
                  <div className="centerItem">
                    <Text text="You can watch the video once only" fontWeight="semibold" />
                  </div>
                  <div className="coverImage videoBgColor" style={{ width: videoWidth, height: videoHeight }}>
                    <Image
                      className="hoverPlayButton"
                      SourceImage={PlayButtonSvg}
                      allowHovering={true}
                      onClick={() => {
                        videoRef.current?.play(); setScreenState('Playing')
                      }} />
                  </div>
                </div>
              )}
              {screenState === 'Playing' && !props?.isVideoPlayed && (
                <div >
                  <div className="videoCls">
                    <video
                      width={videoWidth}
                      height={videoHeight}
                      ref={videoRef}
                      src={videoPath}
                      autoPlay
                      onEnded={() => {
                        setScreenState('Ended');
                        props.setIsVideoPlayed(true);
                        if (apiUpStatus) {
                          props.setNextButtonDisabled(false);
                          props.setPrevButtonDisabled(false);
                        }
                      }}
                      onLoadedMetadata={() => {
                        console.log("Guidance video exact duration: ", videoRef.current?.duration);
                        if (videoRef.current) {
                          setVideoDuration(Math.round(videoRef.current?.duration));
                        }
                      }}
                    />
                  </div>
                  <TimerProgressBar
                    timerBarDuration={videoDuration}
                    showTime={true}
                    endTime={videoDuration}
                  />
                </div>
              )}
              {(screenState === 'Ended' || props?.isVideoPlayed) && apiUpStatus && (
                <div className="coverImage" style={{ width: videoWidth, height: videoHeight }}>
                  <Text text="Click <span class='bolderString'>Next</span> to begin your speaking test" fontWeight="semibold" />
                </div>
              )}
            </div>
            {
              screenState === 'Ended' && !apiUpStatus && (
                <div className="error">
                  <div>
                    <span><Error className="icon" /> <Text text="An error has occured" type='error' /></span>
                    <div className="errorDetails">
                      <Text text="The service is currently unavailable" fontWeight="semibold" />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
      </Card>
    </div>
  );
};

export default SummaryScreen;
