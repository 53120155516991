import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import type { FooterProps } from './Footer.types';
import classes from './Footer.module.css';

export const Footer = (props: PropsWithChildren<FooterProps>) => {
    const { children, className } = props;

    return <div className={clsx(classes.root, className)}>{children}</div>;
};

Footer.displayName = 'Footer';
