import React, { useState, useEffect, useRef } from "react";
import Image from "../image/Image";
import { timeProgressBar } from "../../constants/constants";
import './TimerProgressBar.css';
import { TimeProgress } from "../TimeProgress";
import { TimerClockIcon } from "../../assets/icons/TimerClockIcon";
/**
 * @returns timerbar component
 * This component display a timer progress bar when the part to monolog question required an answer
 * Progress bar will be displayed for 120 seconds for capturing the answer
 * It also displays messages whenever the timer starts and 1 minute remaining the timer
 * Time duration i.e. 120 seconds and messages configured in constants.ts file
 */

const TimerProgressBar = ({ timerBarDuration = timeProgressBar.DURATION, showTime = false, endTime = 0 }) => {
  const [progress, setProgress] = useState(0);
  const timerMSg = useRef("");
  const timeStart = useRef("00:00");

  // Code to calculate seconds to display in Sound check screen
  const toMMSS = (sec: any) => {
    const minutes = Math.floor(sec / 60).toString().padStart(2, '0');
    const remainingSeconds = (sec % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  }

  // Code to set time start and end for the timer
  useEffect(() => {
    const interval = 100; // Update interval in milliseconds
    const totalTicks = timerBarDuration === 0 ? 0 : (timerBarDuration * 1000) / interval;
    if(timerBarDuration === 0 && showTime){
      timeStart.current = toMMSS(0);
    }
    let tick = 0;

    // Code to caluculate seconds to display in Sound check screen
    const timer = setInterval(() => {
      tick += 1;
      let percentage =  timerBarDuration === 0 ? 0 : (tick / totalTicks) * 100;
      
      // Code to animate the progress bar
      setProgress(percentage);
      
      if (tick >= totalTicks) {
        clearInterval(timer);
      }

      if(showTime){
        if(tick % 10 === 0) {
          // Code to update time start and end for the timer when sound check starts
          timeStart.current = toMMSS(tick * interval / 1000);
        }
      }else if(!showTime) {
        // Code to display the time related messages
        if (percentage < 3) 
          timerMSg.current = timeProgressBar.YOUR_TIME_STARTS_NOW;
        else if (percentage > 49 && percentage < 53)
          timerMSg.current = timeProgressBar.ONE_MINUTE_LEFT;
        else 
          timerMSg.current = "";
      }

    }, interval);

    return () => clearInterval(timer); // Clean up interval on component unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerBarDuration]);

  return (
    <div>
      {/* Time Progress component for the progress bar with start and end time */}
      <TimeProgress showTime={showTime} progress={progress} startTime={timeStart.current} endTime={toMMSS(endTime)}/>
      {!showTime && timerMSg.current !== "" && (
        <div className="timeMsgColor" style={styles.notityMSg}>
          <Image SourceImage={TimerClockIcon} imageName="Timer Clock" />
          <span style={styles.timeRemaining}>
            <b>{timerMSg.current}</b>
          </span>
        </div>
      )}
    </div>
  );
};

const styles = {
  notityMSg: {
    marginTop: "5px",
    backgroundColor: "transparent",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    alignProperty: "center",
    justifyContent: "center",
  },
  timeRemaining: {
    marginTop: "5px",
    marginLeft: "8px",
  },
};

export default TimerProgressBar;