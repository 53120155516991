import * as React from 'react';
import { StepProps, StepInternalProps } from './Stepper.types';
import { Text } from '../Text';

import classes from './Stepper.module.css';
import clsx from 'clsx';

export const Step = (props: StepProps) => {
    const { isDone, active, isLast, isNextActive } = props as StepInternalProps;

    return (
        <div style={{ width: '100%' }} className={classes['step-wrapper']}>
            <div className={classes['step-icon-wrapper']} data-testid="stepper-icon">
                <div>
                    {props.icon ? (
                        <props.icon.type isDone={isDone} currentActive={active} />
                    ) : (
                        <div className={classes['step-icon-default']} />
                    )}
                </div>
                {!isLast && (
                    <div
                        className={clsx(classes['step-separator'], {
                            [classes['step-separator-active']]: isNextActive,
                        })}
                    />
                )}
            </div>
            <div className={classes['step-label']} data-testid="stepper-label">
                <Text text={props.label} fontWeight="semibold" disabled={!isDone} />
            </div>
        </div>
    );
};
