export const REACT_APP_API_URL = process.env.REACT_APP_API_URL ?? '';
export const REACT_APP_COGNITO_DOMAIN_URL = process.env.REACT_APP_COGNITO_DOMAIN_URL ?? '';
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION ?? '';
export const REACT_APP_COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID ?? '';
export const REACT_APP_COGNITO_USERPOOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID ?? '';
export const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL ?? '';
export const apiUrl = {
  ACCESS_TOKEN_API_URL: REACT_APP_COGNITO_DOMAIN_URL + 'oauth2/token',
  UPLOAD_AUDIO_FILE: "/api/uploadAudio",
  GET_P1_QUESTIONS: "/api/get_p1_questions",
  GET_P3_QUESTIONS: "/getPartThreeContent",
  GET_PROBES: "/getProbes",
  GET_CONTENT_DATA: "/getContent",
  GET_SESSION_ID_URL: "/userSession/uniqueSession",
  INTENT_AUDIO_DETECTION: "intentDetectionAudio",
  SPEAKING_TEST_PROGRESSION_DATA: "/speakingTestProgression",
  HEALTH_CHECK: "/actuator/health"
};

export const awsExports = {
  Auth: {
    region: REACT_APP_COGNITO_REGION,
    Cognito: {
      userPoolId: REACT_APP_COGNITO_USERPOOL_ID,
      userPoolClientId: REACT_APP_COGNITO_USERPOOL_CLIENT_ID,
    },
    oauth: {
      domain: REACT_APP_COGNITO_DOMAIN_URL,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: REACT_APP_API_URL,
      redirectSignOut: REACT_APP_API_URL,
      responseType: "code",
    },
  },
};

export const commonValues = {
  POST: "POST",
  SESSION_ID: "Jun2024",
};

export const loginTexts = {
  ENTER_TEXT: "Enter",
  PAGE_TITLE: "Signin with Email",
  INVALID_CREDENTIAL: "Invalid Credential",
  EMAIL_LABEL: "Email Id",
  USERNAME_LABEL: "Username",
  PASSWORD_LABEL: "Password",
  SIGNIN_BTN_LABEL: "Sign in",
  PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  FORGOT_PASSWORD: "Forgot Password",
  BACK: "Back to Login",
  SEND_CODE: "Send Code",
  CODE_SENT: "Code has been sent to your mail id",
  CODE: "Code",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  RESET_PASSWORD: "Reset Password",
  SUCCESS: "SUCCESS",
  SUCCESS_PASSWORD: "Password has been changed successfully",
  INVALID_CODE: "Entered invalid code",
  CODE_FAILED: "Sending code failed",
  LOGIN_SECTION_NAME: "Test Taker Login"
};

export const errorMessages = {
  EMPTY_EMAIL: "Please enter email id",
  INVALID_EMAIL: "Please enter valid email id",
  EMPTY_USERNAME: "Please enter username",
  EMPTY_PASSWORD: "Please enter password",
  EMPTY_CODE: "Please enter code received in the mail",
  EMPTY_NEW_PASSWORD: "Please enter new password",
  EMPTY_CONFIRM_PASSWORD: "Please enter confirm password",
  MISMATCH_PASSWORD: "New password and confirm password are not matching",
};

export const animationModes = {
  IDLE: "idle",
  LISTENING: "listening",
  ENCOURAGING: "encouraging",
  TALKING: "talking",
  TALKING_DURATION: 10,
  TIMER_VALUE: 60000
}

export const audioPlayReasons = {
  PLAYING: "playing",
  NOT_PLAYING: "notPlaying",
  NO_RESPONSE: "No Response",
  NOT_MUCH_RESPONDED: "Not Responded Much",
  MAX_DURATION_REACHED: "Max Duration Reached",
  QUESTION_COMPLETED: "Question Completed",
  NOT_STARTED: "Not Started",
  RECEIVED: "Received",
  IN_PROGRESS: "In Progress",
  CAN_RESTART: "Can Restart",
  MAX_DURATION_FOR_PART_REACHED: "Max Duration for part reached"

}

export const timeProgressBar = {
  DURATION: 120, // 120 seconds
  YOUR_TIME_STARTS_NOW: "Your time starts now",
  ONE_MINUTE_LEFT: "1 minute left",
}

export const appThemeNames = {
  BLACK_ON_WHITE: "black_on_white",
  WHITE_ON_BLACK: "white_on_black",
  YELLOW_ON_BLACK: "yellow_on_black"
}

export const intentResponse = {
  REPEAT: "repeat",
  MOVE_ON: "move-on",
  STUCK: "stuck"
}

export const MAX_DURATION = 15000;

export const MIN_DURATION = 0;

export const RULE_TWO_SILENCE_DURATION = 3;

export const RULE_ONE_SILENCE_DURATION = 5;

export const RULE_FOUR_MAX_COUNTER = 1;

export const RULE_THREE_MIN_DURATION = 3;

export const RULE_TWO_PART_2_SILENCE_DURATION = 5;

export const INTENT_DETECTION_TIME = 3;

export const Parts = {
  PART_1: 'part1',
  PART_2: 'part2',
  PART_3: 'part3'
}

export const PartsMaxDuration:any = {
  part1: 300,
  part2: 240,
  part3: 300
}