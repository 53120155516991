import { useState } from "react";
import { Card } from "../../components/Card";
import "./LoginScreen.css";
import {
  Box,
  FormGroup,
  TextField,
  InputLabel
} from "@mui/material";
import { Button } from "../../components/Button";
import {
  loginTexts,
  errorMessages,
  awsExports,
} from "../../constants/constants";
import Logo from "../../logo.svg";

// Redux state and dispatch hook for accessing and updating the Redux store.
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { changeAccessTokenValue, changeTestTakerUsernameValue, changeTestTaker, changeTestTakerSessionIdValue } from "../../redux/appConfigSlice";

import { useNavigate } from "react-router-dom";
import {
  signIn,
  signOut,
  getCurrentUser,
  fetchAuthSession,
} from "@aws-amplify/auth";

// Configure Amplify in index file or root file
// import { Amplify } from "@aws-amplify/core";
import { Amplify } from "aws-amplify";
import { fetchTestTakerSessionId } from "../../api/authApis";
Amplify.configure(awsExports);

/**
 * Environment variable to determine whether the ILETS logo should be displayed or 
 * not in the application and in browser tab.
 * Which is managed by the the pipeline environment variable
 */
const showILETSLogo = process.env.REACT_APP_SHOW_LOGO ?? "false";

// Login screen component that displays the Login of the selected options.
const LoginScreen = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [reqObject, setReqObject] = useState({
    email: "",
    password: ""
  });
  const schemaErrorObj = {
    emailError: false,
    emailErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  };
  const schemaLoginErrorObj = {
    loginError: false,
    loginErrorMessage: "",
  };
  const [errorObj, setErrorObj] = useState(schemaErrorObj);
  const [loginErrorObj, setLoginErrorObj] = useState(schemaLoginErrorObj);
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  /**
   * Styles for the components
   */
  const styleBox = { height: 42, marginTop: "15px" };
  
  const styleInputLabel = {
    fontSize: "14px",
    marginBottom: "20px",
    marginLeft: "5px",
    fontWeight: "normal",
  };

  /**
   * Fetch the current user session id
   * @param testTakerAccessToken 
   * @param username 
   */
  const getTestTakerSessionId = async (testTakerAccessToken: string | undefined, username: any) => {
    try {
      // Get the session info from the API for the given testTakerId.
      const uniqueSessionInfo = await fetchTestTakerSessionId(testTakerAccessToken, username);
      if (uniqueSessionInfo) {
        dispatch(changeTestTakerSessionIdValue(uniqueSessionInfo?.data));
      }
    } catch (error: any) {
      //Display the session id error message
      setLoginErrorObj({
        loginError: true,
        loginErrorMessage: error,
      });
    }
  };

  // form validation
  const formValidation = () => {
    let errorFlag = false;
    if (reqObject.email === "" && reqObject.password === "") {
      setErrorObj({
        emailError: true,
        emailErrorText: errorMessages.EMPTY_USERNAME,
        passwordError: true,
        passwordErrorText: errorMessages.EMPTY_PASSWORD,
      });
      errorFlag = true;
    }
    else if (reqObject.email === "") {
      setErrorObj({
        ...errorObj,
        emailError: true,
        emailErrorText: errorMessages.EMPTY_USERNAME,
        passwordError: false,
        passwordErrorText: "",
      });
      errorFlag = true;
    }
    else if (reqObject.password === "") {
      setErrorObj({
        ...errorObj,
        emailError: false,
        emailErrorText: "",
        passwordError: true,
        passwordErrorText: errorMessages.EMPTY_PASSWORD,
      });
      errorFlag = true;
    }
    // else if (!validateEmail(reqObject.email)) {
    //   setErrorObj({
    //     ...errorObj,
    //     emailError: true,
    //     emailErrorText: errorMessages.INVALID_EMAIL,
    //   });
    //   errorFlag = true;
    // }
    else {
      setErrorObj({
        emailError: false,
        emailErrorText: "",
        passwordError: false,
        passwordErrorText: "",
      });
    }
    return errorFlag;
  };

  // Method to handle the successful login and save the username, userId and token information in redux storage
  const handleLoginSuccess = async () => {
    setShowLogin(false);
    let userDetails = await getCurrentUser();
    let session = await fetchAuthSession();
    let testTakerAccessToken = session?.tokens?.accessToken.toString();
    let tempUserName = userDetails?.signInDetails?.loginId;
    let testTakerId = userDetails?.userId;
    // Save the access token, testTaker username and testTaker id in Redux storage
    dispatch(changeAccessTokenValue(testTakerAccessToken));
    dispatch(changeTestTakerUsernameValue(tempUserName));
    dispatch(changeTestTaker({ testTakerId: testTakerId }));
    navigate('/precheck');
    // Set testTaker sessionId
    /**
     * @params accessToken and username value to to set the unique sessionId
     * username of testing-user@cambridgeTesting would be testing-user only
     */
    let tempSplitUsername = tempUserName?.split("@")[0];
    getTestTakerSessionId(testTakerAccessToken, tempSplitUsername);
  };

  // method to call on submit
  const handleSubmit = async () => {
    const loginObj = {
      username: reqObject.email,
      password: reqObject.password,
    };
    setLoginErrorObj({ loginError: false, loginErrorMessage: "" });
    // signout if loggedin already
    try {
      await signOut();
      // console.log("SignOut completed")
    } catch (error) {
      // console.log("SignOut already completed")
    }
    if (!formValidation()) {
      setIsLoading(true);
      signIn(loginObj)?.then(
        (loginResponse: any) => {
          setIsLoading(false);
          if (loginResponse.isSignedIn) {
            handleLoginSuccess();
          } else {
            setLoginErrorObj({
              loginError: true,
              loginErrorMessage: loginResponse.nextStep?.signInStep
            });
          }
        },
        (error: any) => {
          setIsLoading(false);
          setLoginErrorObj({
            loginError: true,
            loginErrorMessage: error.message,
          });
          console.log("Sign in error: ", error);
        }
      );
    }
  };

  // key up event triggered from email and password fields
  const onKeyUp = (event: any) => {
    // if enter is pressed, calling handleSubmit method
    if (event.key === loginTexts.ENTER_TEXT) {
      if (showLogin) {
        handleSubmit();
      }
    }
  };
  return (
    <div className={`flex login_main_container`}>
      <Card className="login_container">
          <div>
              {(showILETSLogo === "true") &&
                <Box
                  component="img"
                  sx={styleBox}
                  alt="Cambridge logo"
                  src={Logo}
                />
              }

              <FormGroup>
                <TextField
                  disabled={isLoading}
                  required
                  value={reqObject.email}
                  id="text"
                  type="text"
                  label={loginTexts.USERNAME_LABEL}
                  variant="outlined"
                  margin="normal"
                  error={errorObj.emailError}
                  helperText={errorObj.emailErrorText}
                  onChange={(event) => {
                    setErrorObj({
                      ...errorObj,
                      emailError: !event.target.value,
                      emailErrorText: event.target.value ? "" : errorMessages.EMPTY_USERNAME,
                    });
                    setReqObject({
                      email: event.target.value,
                      password: reqObject.password,
                    });
                  }}
                  onKeyUp={onKeyUp}
                />
                <TextField
                  disabled={isLoading}
                  required
                  value={reqObject.password}
                  id="password"
                  type="password"
                  label={loginTexts.PASSWORD_LABEL}
                  variant="outlined"
                  margin="normal"
                  error={errorObj.passwordError}
                  helperText={errorObj.passwordErrorText}
                  onChange={(event) => {
                    setErrorObj({
                      ...errorObj,
                      passwordError: !event.target.value,
                      passwordErrorText: event.target.value
                        ? ""
                        : errorMessages.EMPTY_PASSWORD,
                    });
                    setReqObject({
                      email: reqObject.email,
                      password: event.target.value,
                    });
                  }}
                  onKeyUp={onKeyUp}
                />
                {loginErrorObj.loginError && (
                  <InputLabel
                    size="small"
                    color="error"
                    style={styleInputLabel}
                    variant="standard"
                    focused
                  >
                    {loginErrorObj.loginErrorMessage}
                  </InputLabel>
                )}
                <Button
                  onClick={handleSubmit}
                  variant="primary"
                  size="lg"
                  loading={isLoading}
                  style={{
                    fontFamily: 'var(--body-font)',
                    fontWeight: 'var(--font-semibold)'
                  }}
                >
                  {loginTexts.SIGNIN_BTN_LABEL}
                </Button>
              </FormGroup>
            </div>
        </Card>
    </div>
  );
};

export default LoginScreen;