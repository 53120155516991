import { SVGAttributes } from 'react';

export const DotIcon = ({ className, ...rootProps }: SVGAttributes<SVGSVGElement>) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        {...rootProps}
    >
        <circle cx="6" cy="6.33398" r="6" fill="currentColor" />
    </svg>
);

DotIcon.displayName = 'DotIcon';
