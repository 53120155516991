import React from 'react';
import { IconProps } from './types';
import classes from './styledIcons.module.css';
import clsx from 'clsx';

export const MicIcon = (props: IconProps) => {
    const wrapperContainerClass = props.currentActive ? classes.iconBorderActive : classes.iconBorderInactive;
    const wrapperClass = props.isDone ? classes.active : classes.inactive;
    const iconBGFillClass = props.isDone ? classes.iconBGActive : classes.iconBGInactive;
    const iconFillClass = props.isDone ? classes.iconColorActive : classes.iconColorInactive;

    return (
        <div className={clsx(classes.wrapperContainer, wrapperContainerClass)}>
            <div className={clsx(classes.wrapper, wrapperClass)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" className={iconBGFillClass} />
                    <mask id="mask0_7001_6090" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
                        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7001_6090)">
                        <path
                            d="M12 28C11.7167 28 11.4792 27.9042 11.2875 27.7125C11.0958 27.5208 11 27.2833 11 27C11 26.7167 11.0958 26.4792 11.2875 26.2875C11.4792 26.0958 11.7167 26 12 26C12.2833 26 12.5208 26.0958 12.7125 26.2875C12.9042 26.4792 13 26.7167 13 27C13 27.2833 12.9042 27.5208 12.7125 27.7125C12.5208 27.9042 12.2833 28 12 28ZM16 28C15.7167 28 15.4792 27.9042 15.2875 27.7125C15.0958 27.5208 15 27.2833 15 27C15 26.7167 15.0958 26.4792 15.2875 26.2875C15.4792 26.0958 15.7167 26 16 26C16.2833 26 16.5208 26.0958 16.7125 26.2875C16.9042 26.4792 17 26.7167 17 27C17 27.2833 16.9042 27.5208 16.7125 27.7125C16.5208 27.9042 16.2833 28 16 28ZM20 28C19.7167 28 19.4792 27.9042 19.2875 27.7125C19.0958 27.5208 19 27.2833 19 27C19 26.7167 19.0958 26.4792 19.2875 26.2875C19.4792 26.0958 19.7167 26 20 26C20.2833 26 20.5208 26.0958 20.7125 26.2875C20.9042 26.4792 21 26.7167 21 27C21 27.2833 20.9042 27.5208 20.7125 27.7125C20.5208 27.9042 20.2833 28 20 28ZM16 18C15.1667 18 14.4583 17.7083 13.875 17.125C13.2917 16.5417 13 15.8333 13 15V9C13 8.16667 13.2917 7.45833 13.875 6.875C14.4583 6.29167 15.1667 6 16 6C16.8333 6 17.5417 6.29167 18.125 6.875C18.7083 7.45833 19 8.16667 19 9V15C19 15.8333 18.7083 16.5417 18.125 17.125C17.5417 17.7083 16.8333 18 16 18ZM15 25V21.9C13.2667 21.6667 11.8333 20.8958 10.7 19.5875C9.56667 18.2792 9 16.75 9 15H11C11 16.3833 11.4875 17.5625 12.4625 18.5375C13.4375 19.5125 14.6167 20 16 20C17.3833 20 18.5625 19.5125 19.5375 18.5375C20.5125 17.5625 21 16.3833 21 15H23C23 16.75 22.4333 18.2792 21.3 19.5875C20.1667 20.8958 18.7333 21.6667 17 21.9V25H15ZM16 16C16.2833 16 16.5208 15.9042 16.7125 15.7125C16.9042 15.5208 17 15.2833 17 15V9C17 8.71667 16.9042 8.47917 16.7125 8.2875C16.5208 8.09583 16.2833 8 16 8C15.7167 8 15.4792 8.09583 15.2875 8.2875C15.0958 8.47917 15 8.71667 15 9V15C15 15.2833 15.0958 15.5208 15.2875 15.7125C15.4792 15.9042 15.7167 16 16 16Z"
                            className={iconFillClass}
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};
