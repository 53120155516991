import { Canvas } from '@react-three/fiber';
import clsx from 'clsx';

import type { AvatarSceneProps } from './AvatarScene.types';
import { Avatar } from './Avatar/Avatar';
import classes from './AvatarScene.module.css';
import { Card } from '../Card';

export const AvatarScene = (props: AvatarSceneProps) => {
    const { mode, className } = props;
    return (
        <Card className={clsx(classes.root, className, { [classes.talking]: mode === 'TALKING' })}>
            <Canvas
                camera={{
                    fov: 25,
                }}
                className={classes.canvas}
            >
                <Avatar mode={mode} />
            </Canvas>
        </Card>
    );
};

AvatarScene.displayName = 'AvatarScene';
