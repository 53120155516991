const key = process.env.REACT_APP_AES_CBC_KEY; // 32 bytes
const iv = process.env.REACT_APP_AES_CBC_IV; // 16 bytes

const encoder = new TextEncoder();
const decoder = new TextDecoder();

const ivBuffer = encoder.encode(iv);
const keyBuffer = encoder.encode(key);

export const decryptURL = async (encryptedURL: string) => {
  // Decode the encrypted url string to ArrayBuffer
  const encryptedBuffer = Uint8Array.from(atob(encryptedURL), (c) =>
    c.charCodeAt(0)
  );

  // Convert Key from ArrayBuffer to CryptoKey object
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  // Decryption
  const decryptedBuffer = await crypto.subtle.decrypt(
    { name: "AES-CBC", iv: ivBuffer },
    cryptoKey,
    encryptedBuffer
  );

  // Decode the decrypted data to a string
  return decoder.decode(decryptedBuffer);
};
