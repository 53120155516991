import { useEffect, useState } from "react";
import { Card } from "../../components/Card";
import "./SoundSelection.css";
import Image from "../../components/image/Image";
import AudioSrc from '../../assets/audio_files/volume-sample.mp3';
import { Text } from '../../components/Text';

// Importing the AudioPlayer component for playing audio files.
import AudioPlayer from "../../audio/audioPlayer";

/**
 * Importing the necessary icons for the SoundSelection component.
 * The Headphones, PlayButton, and ExitButton are imported from the assets/icons folder as React components.
 */
import TimerProgressBar from "../../components/timerProgressBar/TimerProgressBar";
import { PlayButtonIcon } from "../../assets/icons/PlayButtonIcon";
import { PauseButtonIcon } from "../../assets/icons/PauseButtonIcon";

/**
 * @returns It will return a SoundSelection component.
 * Test Taker test headphones sound selection.
 * The SoundSelection component displays a play/pause button, an exit button, and a slider for audio level adjustment.
 * The play/pause button changes based on the current audio state.
 * The Headphone and exit buttons do nothing.
 */

const SoundSelection = () =>{
  /**
   * @param e the event object containing the new audio level value.
   * It updates the audio level in redux and sets the new audio level value in the state.
   */

  /**
   * @param value as the new audio file for playAudioAsync, it will play the audio file
   */
  const audioPlayer = AudioPlayer(AudioSrc);
  // The useRef hook is used to create a mutable ref object.
  const [audioDuration, setAudioDuration] = useState(0);
  /**
   * Exact duration of the volume-sample.mp3 audio file is 4 seconds,
   * If volume-sample.mp3 audio file changes, must need to update the below 
   * audioEndTimeDuration with actual duration of that audio file.
   */
  const [audioEndTimeDuration, setAudioEndTimeDuration] = useState(4);

  useEffect(()=>{
    let aDuration = audioPlayer?.audioRoundedDuration();
    if(aDuration !== 0){
      setAudioEndTimeDuration(aDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioPlayer?.audioRoundedDuration]);
  
  /**
   * @returns Plays the audio file when the play button is clicked.
   */
  const playAudioAsync = () => {
    if(!audioPlayer.isPlaying){
      setAudioDuration(0);
      setTimeout(()=>{
        setAudioDuration(audioEndTimeDuration);
        audioPlayer.playAudio();
      }, 1000)
    }
  }
  
  /**
   * @returns The SoundSelection component with play/pause button, exit button, and audio level slider.
   */
  return (
    <div className={`flex soundBlockNew`}>
      <Card className="contentGrow" >
          <>
            <div className="centerContent">
              <p>
                <Text text="Put on you headphones and play the audion below" variant="body" fontWeight="semibold" />
              </p>
            </div>
              <div className="progressCard" >
                  <>
                    <TimerProgressBar timerBarDuration={audioDuration} showTime={true} endTime={audioEndTimeDuration}/>
                    <div data-testid="play-image" role="presentation" onClick={playAudioAsync} >
                      <Image SourceImage={!audioPlayer.isPlaying ? PlayButtonIcon : PauseButtonIcon} className="middleSvg" allowHovering={true}/>
                    </div>
                  </>
              </div>
            <div className="centerContent">
              <p>
                <Text text="Adjust the volume on your device until you can hear clearly" variant="body" fontWeight="semibold" />
              </p>
            </div>
            <div className="centerContent">
              <p>
                <Text text="When you are happy with the sound, click <span class='bolderString'>Next</span> to proceed" variant="body" fontWeight="semibold" />
              </p>
            </div>
          </>
      </Card>
    </div>
  );
};

export default SoundSelection;