import { useEffect, useState } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import './MicrophoneSelection.css';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
// Importing the necessary hooks for Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
// Redux imports for theme change
import { changeSelectedMic } from '../../redux/appConfigSlice';
import { Dropdown } from '../../components/Dropdown';
import { DropdownItem } from '../../components/Dropdown/DropdownItem';

/* Display the microphone selection screen with audio visualizer and microphone options */
const MicrophoneSelectionScreen = (props: any) => {
    const [micList, setMicList] = useState([]);
    const [selectedMicId, setSelectedMicId] = useState('');
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

    let recorderConfig = { ...AudioRecorderDefaultConfig };
    let audioRecorder = useAudioRecorder({
        ...recorderConfig,
    });
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        getMicrophoneListAsync();
        window.navigator.mediaDevices.addEventListener('devicechange', () => {
            getMicrophoneListAsync();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Get the list of available microphones and start recording with default one */
    const getMicrophoneListAsync = async () => {
        const allDevices = await window.navigator.mediaDevices.enumerateDevices();
        const micList = allDevices.filter((d) => d.kind === 'audioinput');
        let filteredMicList: any = [];
        let defaultGroupId = micList.find((d: any) => d.deviceId === 'default')?.groupId;
        micList.forEach((mic) => {
            if (/^[0-9a-f]+$/i.test(mic.deviceId)) {
                filteredMicList.push({
                    label: mic.label,
                    deviceId: mic.deviceId,
                    groupId: mic.groupId,
                });
            }
        });
        setMicList(filteredMicList);
        setSelectedMicId(filteredMicList[0].deviceId);
        dispatch(changeSelectedMic(filteredMicList[0].deviceId));
        let defaultDeviceId = defaultGroupId && filteredMicList.find((d: any) => d.groupId === defaultGroupId)?.deviceId;
        setSelectedMicId(defaultDeviceId);
        startRecord();
    };

    useEffect(() => {
        if (props.prevButtonClicked) {
            stopRecording();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prevButtonClicked]);

    /* Start recording with the selected microphone */
    const startRecord = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });
        setMediaStream(stream);
        audioRecorder.startRecording();
    };

    /* Stop recording and stop the media stream */
    const stopRecording = async (nextBtn = false) => {
        audioRecorder.stopRecording();
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
        }
        if (nextBtn) {
            dispatch(changeSelectedMic(selectedMicId));
            props.setNextButtonDisabled(true);
        }
    };

    return (
        <div>
            <div>
                <Dropdown
                    onSelect={(val) => {
                        stopRecording(true);
                        setSelectedMicId(val);
                    }}
                    value={selectedMicId}
                    label="Choose your microphone"
                >
                    {micList.map((mic: any) => (
                        <DropdownItem label={mic.label} key={mic.deviceId} value={mic.deviceId} />
                    ))}
                </Dropdown>
            </div>
        </div>
    );
};

export default MicrophoneSelectionScreen;
