import clsx from 'clsx';

import type { RecordingBarProps } from './RecordingBar.types';
import classes from './RecordingBar.module.css';
import { Card } from '../Card';
import { LiveAudioVisualizer } from '../LiveAudioVisualizer';
import { DotIcon } from '../../assets/icons';
import { Text } from '../Text';

export const RecordingBar = (props: RecordingBarProps) => {
    const { mediaRecorder, className } = props;

    return (
        <Card className={clsx(classes.root, className)}>
            <LiveAudioVisualizer mediaRecorder={mediaRecorder} />
            <div className={classes.text}>
                <DotIcon
                    className={clsx(classes.icon, {
                        [classes.iconRecording]: mediaRecorder?.state === 'recording',
                    })}
                    data-testid = "record-icon"
                />
                <Text text="Recording ..." fontWeight="semibold" />
            </div>
        </Card>
    );
};

RecordingBar.displayName = 'RecordingBar';
