import { forwardRef } from 'react';
import clsx from 'clsx';

import type { ButtonProps } from './Button.types';
import classes from './Button.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (props: ButtonProps, ref) => {
        const {
            className,
            disabled,
            children,
            loading = false,
            size = 'sm',
            variant = 'primary',
            iconLeft: IconLeft,
            iconRight: IconRight,
            ...rootProps
        } = props;

        return (
            <button
                className={clsx(classes.root, className, classes[size], classes[variant], {
                    [classes.disabled]: disabled,
                    [classes.loading]: loading,
                })}
                disabled={disabled || loading}
                {...rootProps}
                ref={ref}
            >
                {!loading && IconLeft && (
                    <IconLeft
                        className={clsx(classes.icon, { [classes.iconSmall]: size === 'sm' })}
                        data-testid="icon-left"
                    />
                )}
                {children}
                {!loading && IconRight && (
                    <IconRight
                        className={clsx(classes.icon, { [classes.iconSmall]: size === 'sm' })}
                        data-testid="icon-right"
                    />
                )}
                {loading && (
                    <LoadingSpinner
                        className={clsx(classes.spinner, { [classes.spinnerSmall]: size === 'sm' })}
                        data-testid="spinner"
                    />
                )}
            </button>
        );
    }   
);

Button.displayName = 'Button';
