import { SVGAttributes } from 'react';

export const IELTSLogoIcon = ({ className, ...rootProps }: SVGAttributes<SVGSVGElement>) => (
    <svg
        className={className}
        width="85"
        height="38"
        viewBox="0 0 85 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rootProps}
    >
        <path d="M14.417 9.5H9.53516V28.5H14.417V9.5Z" fill="currentColor" />
        <path
            d="M16.4258 9.5H31.1733V13.386H21.3077V16.8741H31.1465V20.6773H21.3077V24.6167H31.2269V28.5H16.4258V9.5Z"
            fill="currentColor"
        />
        <path d="M33.0586 9.5H37.9646V24.379H46.9322V28.5H33.0586V9.5Z" fill="currentColor" />
        <path
            d="M48.7644 13.3593H43.3008V9.5H59.1367V13.3593H53.6436V28.5H48.7644V13.3593Z"
            fill="currentColor"
        />
        <path
            d="M64.0302 22.1831C64.0838 24.3518 65.3036 25.4602 67.6386 25.4602C69.6815 25.4602 70.7672 24.7471 70.7672 23.5826C70.7672 22.9496 70.475 22.4742 70.0246 22.2365C68.8852 21.6543 66.7084 21.2857 63.9229 20.1747C61.1643 19.0903 59.7327 17.2688 59.7327 14.9159C59.7327 11.3477 62.5718 8.9707 67.3464 8.9707C72.1211 8.9707 74.8261 11.4545 74.8261 15.2337H70.2391C70.0246 13.463 68.9925 12.643 67.0301 12.643C65.384 12.643 64.3787 13.4096 64.3787 14.3604C64.3787 15.7866 65.8639 16.0777 68.8611 17.0579C71.0621 17.771 72.6546 18.2731 73.5821 19.0129C74.8287 20.0171 75.4641 21.3925 75.4641 23.0297C75.4641 26.7288 72.5205 29.0283 67.1614 29.0283C62.2018 29.0283 59.6282 26.7822 59.3359 22.1831H64.0302Z"
            fill="currentColor"
        />
        <path
            d="M78.7168 11.634L79.269 9.96205H79.2824C79.2717 10.2318 79.2637 10.3974 79.2637 10.4588C79.2637 10.5202 79.2637 10.5763 79.2637 10.6244V11.634H79.6872V9.5H79.0679L78.5291 11.1319H78.521L78.0144 9.5H77.3951V11.634H77.7999V10.6404C77.7999 10.5256 77.7918 10.2986 77.7731 9.95938H77.7865L78.3012 11.634H78.7168ZM76.4997 9.87658H77.0814V9.5H75.4648V9.87658H76.0466V11.634H76.4997V9.87658Z"
            fill="currentColor"
        />
    </svg>
);

IELTSLogoIcon.displayName = 'IELTSLogoIcon';
