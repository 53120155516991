import React from 'react';
import { IconProps } from './types';
import classes from './styledIcons.module.css';
import clsx from 'clsx';

export const SoundIcon = (props: IconProps) => {
    const wrapperContainerClass = props.currentActive ? classes.iconBorderActive : classes.iconBorderInactive;
    const wrapperClass = props.isDone ? classes.active : classes.inactive;
    const iconBGFillClass = props.isDone ? classes.iconBGActive : classes.iconBGInactive;
    const iconFillClass = props.isDone ? classes.iconColorActive : classes.iconColorInactive;

    return (
        <div className={clsx(classes.wrapperContainer, wrapperContainerClass)}>
            <div className={clsx(classes.wrapper, wrapperClass)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" className={iconBGFillClass} />
                    <mask id="mask0_7001_6081" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
                        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7001_6081)">
                        <path
                            d="M18 24.7246V22.6746C19.5 22.2413 20.7083 21.4079 21.625 20.1746C22.5417 18.9413 23 17.5413 23 15.9746C23 14.4079 22.5417 13.0079 21.625 11.7746C20.7083 10.5413 19.5 9.70794 18 9.27461V7.22461C20.0667 7.69128 21.75 8.73711 23.05 10.3621C24.35 11.9871 25 13.8579 25 15.9746C25 18.0913 24.35 19.9621 23.05 21.5871C21.75 23.2121 20.0667 24.2579 18 24.7246ZM7 18.9996V12.9996H11L16 7.99961V23.9996L11 18.9996H7ZM18 19.9996V11.9496C18.7833 12.3163 19.3958 12.8663 19.8375 13.5996C20.2792 14.3329 20.5 15.1329 20.5 15.9996C20.5 16.8496 20.2792 17.6371 19.8375 18.3621C19.3958 19.0871 18.7833 19.6329 18 19.9996ZM14 12.8496L11.85 14.9996H9V16.9996H11.85L14 19.1496V12.8496Z"
                            className={iconFillClass}
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};
