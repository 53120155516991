import { useEffect, useState } from 'react';
import FontSelection from '../screens/fontSelection/FontSelection';
import ContrastSelection from './contrastSelection/ContrastSelection';
import SoundSelection from './soundSelection/SoundSelection';
import MicrophoneSelectionMain from './microphoneSelection/MicrophoneSelectionMain';
import SummaryScreen from './summaryScreen/SummaryScreen';
import { Button } from '../components/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '../assets/icons';
import { TextIcon, ColourIcon, SoundIcon, MicIcon, VideoIcon } from '../components/styledIcons';
import { Stepper, StepProps, Step } from '../components/Stepper';

const PersonalizationScreen = (props: any) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [prevButtonClicked, setPrevButtonClicked] = useState(false);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
    const [isVideoPlayed, setIsVideoPlayed] = useState(false);
    const [micPrecheckDone, setMicPrecheckDone] = useState<boolean>(false);
    let indexHavingPrevCallBack = [3];

    useEffect(() => {
        if (
            (prevButtonClicked && !indexHavingPrevCallBack.includes(currentIdx)) ||
            (micPrecheckDone && indexHavingPrevCallBack.includes(currentIdx))
        ) {
            setCurrentIdx(currentIdx - 1);
            setNextButtonDisabled(false);
            setPrevButtonClicked(false);
            setMicPrecheckDone(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevButtonClicked, micPrecheckDone]);

    let onClickPrev = () => {
        setPrevButtonClicked(true);
    };

    const onClickNext = () => {
        setCurrentIdx(currentIdx < 4 ? currentIdx + 1 : props?.setPreChecksDone(true));
    };

    let path = [
        {
            sectionName: 'Choose your font size',
            index: 0,
            Component: <FontSelection />,
        },
        {
            sectionName: 'Choose your colour mode',
            index: 1,
            Component: <ContrastSelection />,
        },
        {
            sectionName: 'Check the sound',
            index: 2,
            Component: <SoundSelection />,
        },
        {
            sectionName: 'Choose the microphone',
            index: 3,
            Component: (
                <MicrophoneSelectionMain
                    setNextButtonDisabled={setNextButtonDisabled}
                    onClickNext={onClickNext}
                    prevButtonClicked={prevButtonClicked}
                    setPrevButtonClicked={setPrevButtonClicked}
                    setMicPrecheck={setMicPrecheckDone}
                />
            ),
        },
        {
            sectionName: `Watch the video`,
            index: 4,
            Component: (
                <SummaryScreen
                    setNextButtonDisabled={setNextButtonDisabled}
                    setPrevButtonDisabled={setPrevButtonDisabled}
                    isVideoPlayed={isVideoPlayed}
                    setIsVideoPlayed={setIsVideoPlayed}
                />
            ),
        },
    ];

    let currentComponent = path[currentIdx];

    const getSteps = (): StepProps[] => {
        return [
            { label: '1. Choose text size', icon: <TextIcon /> },
            { label: '2. Choose colour', icon: <ColourIcon /> },
            { label: '3. Check sound', icon: <SoundIcon /> },
            { label: '4. Check microphone', icon: <MicIcon /> },
            { label: '5. Watch video', icon: <VideoIcon /> },
        ];
    };

    return (
        <div>
            <div className="scrollSection">
                <div className={`centerBlock`}>
                    <div style={{ marginTop: '50px' }}>
                        <Stepper currentActive={currentIdx} width={900}>
                            {getSteps().map((step: StepProps, index) => {
                                return <Step {...step} key={'step' + index} />;
                            })}
                        </Stepper>
                    </div>
                    <div style={{ marginTop: '48px' }}>{currentComponent.Component}</div>
                    <div className="precheckActions">
                        {!!currentIdx && (
                            <Button
                                disabled={prevButtonDisabled}
                                iconLeft={ChevronLeftIcon}
                                variant="secondary"
                                size="xl"
                                onClick={onClickPrev}
                            >
                                Previous
                            </Button>
                        )}
                        <Button iconRight={ChevronRightIcon} disabled={nextButtonDisabled} size="xl" onClick={onClickNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalizationScreen;
