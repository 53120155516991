import { useEffect, useRef, useState } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import { Card } from '../../components/Card';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import {
    connectToIntentAudioSocket,
    getContentData,
    getPart3ContentData,
    getProbes,
    getRating,
    uploadAudioFile,
    saveSpeakingTestProgressionData,
} from '../../api/authApis';
import { isSilenceDetectedForGivenTime } from '../../service/MicService';
import {
    convertWavBlobToArrayBuffer,
    convertWebmToWav,
    formatDate,
    secondsToMinutes,
} from '../../service/utils';
import {
    audioPlayReasons,
    INTENT_DETECTION_TIME,
    intentResponse,
    MAX_DURATION,
    RULE_FOUR_MAX_COUNTER,
    RULE_ONE_SILENCE_DURATION,
    RULE_THREE_MIN_DURATION,
    RULE_TWO_PART_2_SILENCE_DURATION,
    RULE_TWO_SILENCE_DURATION,
    Parts,
    PartsMaxDuration,
} from '../../constants/constants';
import { frameTestQuestions } from '../../service/QuestionService';
import { Text } from '../../components/Text';
import { RecordingBar } from '../../components/RecordingBar';
import { AvatarScene, AvatarSceneProps, animationModes } from '../../components/AvatarScene';
import TimerProgressBar from '../../components/timerProgressBar/TimerProgressBar';
import classes from './TestScreen.module.css'

const TestScreen = () => {
    // Settings or configurations needed for taking test
    const { selectedMic, testTaker, silenceMeanValue } = useSelector(
        (state: RootState) => state.appConfigSlice,
    );
    const audioContextRef = useRef<AudioContext>();
    const mediaRecorderRef = useRef<MediaRecorder>();
    const destinationRef = useRef<MediaStreamAudioDestinationNode>();
    const [fullRecordingBlob, setFullRecordingBlob] = useState();
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<any>(null);
    const [userAudioFileName, setUserAudioFileName] = useState<any>('');
    const [filteredProbeData, setFilteredProbeData] = useState<any>([]);
    const [showTimer, setShowTimer] = useState(false);
    let [endPage, setEndPage] = useState(false);
    let currentPartRef = useRef<any>(Parts.PART_1);
    let questionListRef = useRef<any>([]);
    let playIndexRef = useRef<any>(0);
    let currentQuestionRef = useRef<any>(null);
    let probeListRef = useRef<any>([]);
    let timeOutRef = useRef<number | NodeJS.Timeout>();
    let speakingTimeRef = useRef(0);
    let silentTimeRef = useRef(0);
    let itterationCountRef = useRef(0);
    let questionIndexRef = useRef(0);
    let responseIntervalRef = useRef<number | NodeJS.Timeout>();
    let partIntervalRef = useRef<number | NodeJS.Timeout>();
    let audiRecorder2Ref = useRef<any>();
    let partialRecorderRef = useRef<any>();
    let listeningRef = useRef<number | NodeJS.Timeout>();
    let partIndexRef = useRef<any>();
    let lastSilentDetectionRef = useRef<any>(true);
    let repeatClipRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, counter: 0 });
    let probeRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, index: 2, count: 0 });
    let partTimeRef =  useRef<any>(PartsMaxDuration.part1);
    const [loading, setLoading] = useState(false);
    let fullRecording = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });
    audiRecorder2Ref.current = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });
    partialRecorderRef.current = useAudioRecorder({
        ...AudioRecorderDefaultConfig.audioTrackConstraints,
        deviceId: selectedMic || 'default',
    });
    // Initialize the played audio files array
    let playedAudioFilesArray = useRef<any>([]);
    // Set the audio player state to playing or not playing
    let isAudioPlaying = false;
    // Set the animation mode by default to idle
    const modeRef = useRef<AvatarSceneProps['mode']>('IDLE');
    // Set the varient ref by default to notPlaying and idx to 0
    let variantRef = useRef<any>({ status: audioPlayReasons.NOT_PLAYING, idx: 0 });
    let timerRef = useRef<any>();
    let cardTextRef = useRef<any>();
    let webSocketRef = useRef<any>();
    let intentResponseRef = useRef<any>({ status: audioPlayReasons.NOT_STARTED });
    let dynamicQuestListRef = useRef<any>([]);
    // Declare the variable if the current question is monologueQuestion
    const isMonologueQuestion = useRef(false);
    const timerBarDuration = useRef(0);

    //-------------------- Initialize section  is over -----------------------------//
    /* 
        When `showTimer` is true, the countdown starts, decrementing `timerRef.current` every second.
    * - A `setInterval` function is used to update the timer value every 1000ms (1 second).
    * - When `timerRef.current` reaches 0:
    *   1. The interval is cleared using `clearInterval` to stop the countdown.
    *   2. `setShowTimer(false)` is called to indicate that the timer has ended
    */
    useEffect(() => {
        if (showTimer) {
            let intvalId = setInterval(() => {
                timerRef.current = timerRef.current - 1;
                if (timerRef.current === 0) {
                    clearInterval(intvalId);
                    setShowTimer(false);
                }
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTimer]);


    const startPartTimer = ( duration: any ) => {
        if (partIntervalRef.current) {
            clearInterval(partIntervalRef.current); // Clear any existing interval
        }
        partTimeRef.current = duration;
        partIntervalRef.current = setInterval(() => {
            partTimeRef.current = partTimeRef.current - 1;
            console.log('Part time', partTimeRef.current)
            if (partTimeRef.current === 0) {
                clearInterval(partIntervalRef.current);
                stopRecording('window');
            }
        }, 1000);
    }

    /* Sets the current question and play the question audio
    - Main part of the test, sequentially plays the question from question list
    - Speaking ref, silent ref, repeat ref, variant ref are re-initialized here for new question
    - questionIndex ref is increased as this is the place for moving to next question
    - if question is card statement , then it will assign the markdown text to the cardText ref
    - if reaches the full length of question list, then stops the full recording
    - Once the part is completed, fetches the probe list of upcoming part and change the part ref value as well
    */
    useEffect(() => {
        if (currentQuestionRef.current && currentQuestionRef.current !== null) {
            if( questionIndexRef.current === 0 ) {
                startPartTimer(PartsMaxDuration.part1);
            }
            playAudio(currentQuestionRef.current);
            if (repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING) {
                itterationCountRef.current = 0;
                speakingTimeRef.current = 0;
                silentTimeRef.current = 0;
                questionIndexRef.current = questionIndexRef.current + 1;
                repeatClipRef.current = { status: audioPlayReasons.NOT_PLAYING, counter: 0 };
                variantRef.current = { status: audioPlayReasons.NOT_PLAYING, idx: 0 };
                intentResponseRef.current = { status: audioPlayReasons.NOT_STARTED };
            } else {
                console.log('Avatar is repeating the same question');
            }
        } else {
            if (partIndexRef?.current?.[playIndexRef.current]) {
                currentPartRef.current = partIndexRef?.current[playIndexRef.current];
                let pbData = probeListRef?.current?.filter((pb: any) =>
                    pb.parts[0]?.includes(
                        partIndexRef?.current[playIndexRef.current]?.toUpperCase(),
                    ),
                );
                setFilteredProbeData(pbData);
                questionIndexRef.current = playIndexRef.current;
                startPartTimer(PartsMaxDuration[(currentPartRef.current)])
            }
            currentQuestionRef.current = questionListRef.current[playIndexRef.current];
            setCurrentQuestionInfo(questionListRef.current[playIndexRef.current]);
            if (questionListRef.current[playIndexRef.current]?.showTimer) {
                cardTextRef.current = questionListRef.current[playIndexRef.current]?.markDownText;
            }
            if (playIndexRef.current === questionListRef.current.length) {
                stopFullRecording();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestionRef.current]);

    // upload the audio to s3 of the full recording.
    useEffect(() => {
        if (fullRecordingBlob) {
            (async () => {
                try {
                    const { wavBlob } = await convertWebmToWav(fullRecordingBlob)
                    const responseUpload = await uploadAudioFile(wavBlob, ".wav", `fullrecording`, testTaker);
                    const res = await saveSpeakingTestProgressionData(testTaker, playedAudioFilesArray.current, "Test Completed", responseUpload.data.filePath);
                    console.log("Progression data submitted successfully.", res)
                } catch (error) {
                    console.error(
                        'Error while uploading full recording or submitting test progression data: ',
                        error,
                    );
                } finally {
                    setEndPage(true);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullRecordingBlob]);

    /* fetch content data 
    - Get part 1 and part 2 data from one api
    - Get part 3 content from different api
    */
    const fetchContent = async () => {
        setLoading(true);
        let res = await getContentData(testTaker);
        let part3Res = await getPart3ContentData(testTaker);
        setLoading(false);
        return { part1And2Res: res, part3Res };
    };

    /*
        - Intent detection recording value will be fetched here
        - checks the condition if the recording is available and intent detection is available for this recording
        - if it matches passes the recorded blob object to function for conversion
        - converted blob object will be send to websocket
    */
    useEffect(() => {
        if (
            partialRecorderRef?.current?.recordingBlob &&
            !partialRecorderRef?.current?.mediaRecorder &&
            intentResponseRef.current?.status === audioPlayReasons.IN_PROGRESS
        ) {
            convertAndSendBlob(partialRecorderRef?.current?.recordingBlob);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partialRecorderRef?.current?.mediaRecorder]);

    /*
        Once the whole test is done , this will get execute
        Media recorder also stops here, mic and speaker were suspended on stop
        Web socket will be closed once test is done
    */
    const stopFullRecording = () => {
        fullRecording?.stopRecording();
        mediaRecorderRef?.current?.stop();
        clearInterval(partIntervalRef.current);
        closeSocket();
    };

    /* 
        - Gets the blob object from recorder
        - converts that webm blob object to wav format, which is the accepted format in amazon lex
        - sends the blob object in array buffer to websocket if its opened
    */
    const convertAndSendBlob = async (webmBlob: any) => {
        const { l16Blob } = await convertWebmToWav(webmBlob);
        convertWavBlobToArrayBuffer(l16Blob).then((arrayBuffer: any) => {
            if (webSocketRef.current?.readyState === WebSocket.OPEN && arrayBuffer) {
                webSocketRef.current.send(arrayBuffer);
            }
        });
    };

    /*
        - Dynamically fetches the content for part 3 based on rating
        - If rating is greater than 5, gets the hard content
        - If its lesser than 5 , fetches the medium content
        - Fetched content is pushed to existing array of question list
    */
    const getPart3ContentByRating = () => {
        let rating = 0;
        rating = getRating();
        if (rating > 5) {
            dynamicQuestListRef.current.hardQuestionList?.map((prompt: any) =>
                questionListRef.current.push(prompt),
            );
        } else {
            dynamicQuestListRef.current.mediumQuestionList?.map((prompt: any) =>
                questionListRef.current.push(prompt),
            );
        }
    };

    /* 
        - Stops the user audio and remove the timeout
        - All the operations that are to be done once the TT responded or question is over will be done here
        - It clears all the necessary intervals, timeout and stops all the prompt recording
        - Uploads the prompt recording with prompt id as file name
        - Once all these were done moves to next question
    */
    const stopRecording = (fromSource: any) => {
        // reason putting for if condition is as settime interval running one time after stoping the recording
        if (playIndexRef.current === questionIndexRef.current - 1) {
            clearInterval(responseIntervalRef.current);
            audiRecorder2Ref?.current?.stopRecording();
            partialRecorderRef?.current?.stopRecording();
            clearTimeout(timeOutRef.current);
            clearTimeout(listeningRef.current);
            let fileName = `${playIndexRef.current + 1}_${formatDate(new Date())}`;
            setUserAudioFileName(fileName);
            if (currentQuestionRef.current?.monologueQuestion) {
                cardTextRef.current = '';
            }
            if (partIndexRef?.current[playIndexRef.current + 1] === Parts.PART_3) {
                getPart3ContentByRating();
            }
            if (
                fromSource === 'window' &&
                lastSilentDetectionRef.current === false &&
                !currentQuestionRef.current?.showTimer
            ) {
                interuptClip(audioPlayReasons.MAX_DURATION_REACHED);
            } else {
                currentQuestionRef.current = null;
                setCurrentQuestionInfo(null);
                if( partTimeRef.current <= 20 ) {
                    let nextPartIndex = Object.keys( partIndexRef.current ).find((e: any) => e >  playIndexRef.current )
                    playIndexRef.current = currentPartRef?.current !== Parts.PART_3 ? Number(nextPartIndex) - 1: questionListRef.current.length - 2;
                    clearInterval(partIntervalRef.current);
                } 
                if (playIndexRef.current < questionListRef.current.length - 1) {
                    playIndexRef.current = playIndexRef.current + 1;
                } else {
                    playIndexRef.current = playIndexRef.current + 1;
                    stopFullRecording();
                }
            }
        }
    };

    // handles all animation modes here
    const handleAnimationModes = (mode: any) => {
        modeRef.current = mode;
        console.log('Avatar is ', mode);
    };

    /*
    For part 3 instead of repeat, variant will be played
    - Using variant index ref, it will play the variant sequentially
    - while playing the variants, rules will be off
    - When there is no variant, stops the recording and moved to next prompt
    */
    const playVariant = (reason: any) => {
        let variantIndex = variantRef.current.idx;
        if (!currentQuestionRef?.current?.noIntentDetection && speakingTimeRef.current === 0) {
            partialRecorderRef.current?.stopRecording();
        }
        if (parseInt(variantIndex) < currentQuestionRef.current?.variants?.length) {
            variantRef.current = { status: audioPlayReasons.PLAYING, idx: variantIndex };
            speakingTimeRef.current = 0;
            silentTimeRef.current = 0;
            playProbe(
                currentQuestionRef.current?.variants[variantIndex]?.localizedMedia,
                () => {
                    variantRef.current = {
                        status: audioPlayReasons.NOT_PLAYING,
                        idx: parseInt(variantIndex) + 1,
                    };
                    partialRecorderRef.current?.startRecording();
                },
                reason,
            );
        } else {
            stopRecording('End of Variant');
        }
    };

    /*
    This function will be called on all audios played
    - Here we are the logging the audio path, time of audio played, reasons for audio played
    */
    const handlePlayedAudioFiles = (questionInfo: any, reason: string, type: string) => {
        let audioFileURL =
            type === 'prompt'
                ? questionInfo?.localizedMedia?.audioPathUrl +
                  '/' +
                  questionInfo?.localizedMedia?.audioRef
                : questionInfo?.audioPathUrl + '/' + questionInfo?.audioRef;

        let playedAudioFileObj = {
            audioFileURL: audioFileURL,
            audioFilePlayDateTime: formatDate(new Date()),
            audioFilePlayReason: reason || audioPlayReasons.QUESTION_COMPLETED,
            intent: '',
            transcript: '',
            confidenceScore: null,
        };
        playedAudioFilesArray.current = [...playedAudioFilesArray.current, playedAudioFileObj];
        console.log('Played_Audio_Files_Obj: ', playedAudioFilesArray.current);
    };

    /*
     * Setting true if current question is has monologueQuestion attribute is true otherwise false and
     * timerBarDuration which is difference of question's expectedMaxDuration and audioDuration
     */
    const setTimerBar = (questionInfo: any) => {
        if (questionInfo?.monologueQuestion === true) {
            isMonologueQuestion.current = true;
            if (repeatClipRef.current?.status !== audioPlayReasons.PLAYING) {
                timerBarDuration.current =
                    questionInfo?.expectedMaxDuration -
                    parseInt(questionInfo?.localizedMedia?.audioDuration);
            }
        } else {
            isMonologueQuestion.current = false;
        }
    };

    /* 
        Major functionality of the test
        - Plays the audio, audio is connected to mic and recorder as well
        - Prompt timer starte just before the audio is getting played
        - Prompt recording is getting recorded once the audio is ended
        - Various conditions were added to differentiate between the  prompt and responseless prompt
        - if prompt doesnt need any TT response, it will automatically plays the next one
    */
    const playAudio = (questionInfo: any) => {
        let audioUrl = questionInfo?.localizedMedia?.audioPreSignUrl;
        const audio = new Audio(audioUrl);
        // playback audio volume is reduced , so in case of high sound in system, echo will occur in recording
        // this is temporary solution to test in different env
        audio.volume = 0.6;
        audio.crossOrigin = 'anonymous';
        // handle talking mode here
        handleAnimationModes(animationModes.TALKING);
        const sourceNode = audioContextRef.current?.createMediaElementSource(audio);
        if (destinationRef.current) {
            sourceNode?.connect(destinationRef.current);
        }
        if (audioContextRef?.current) {
            sourceNode?.connect(audioContextRef?.current?.destination);
        }
        audio.play()
            .then((res: any) => {
                isAudioPlaying = true;
                /**
                 * This if block is for to check the expected max duration and 
                 * starting the timer upto the max duration of the question once the question starded playing
                 */
                if (questionInfo?.expectedMaxDuration !== 0 && questionInfo?.expectedMaxDuration !== null) {
                    if (!questionInfo?.showTimer && repeatClipRef.current?.status !== audioPlayReasons.PLAYING) {
                        timeOutRef.current = setTimeout(() => {
                            stopRecording('window');
                        }, questionInfo?.expectedMaxDuration ? questionInfo?.expectedMaxDuration * 1000 : MAX_DURATION)
                    }
                }
                // Call to handle the played audio files
                handlePlayedAudioFiles(questionInfo, audioPlayReasons.QUESTION_COMPLETED, 'prompt');
            })
            .catch((error) => {
                isAudioPlaying = false;
                handleAnimationModes(animationModes.IDLE);
                console.log('Playing inside catch', error);
            });
        audio.onended = () => {
            isAudioPlaying = false;
            handleAnimationModes(animationModes.IDLE);
            setTimerBar(questionInfo);

            if (
                questionInfo?.expectedMaxDuration !== 0 &&
                questionInfo?.expectedMaxDuration !== null
            ) {
                if (!questionInfo?.showTimer) {
                    audiRecorder2Ref?.current.startRecording();
                    if (!questionInfo?.noIntentDetection) {
                        partialRecorderRef?.current?.startRecording();
                    }
                } else {
                    timeOutRef.current = setTimeout(
                        () => {
                            stopRecording('window');
                        },
                        questionInfo?.expectedMaxDuration
                            ? questionInfo?.expectedMaxDuration * 1000
                            : MAX_DURATION,
                    );
                    setShowTimer(true);
                    timerRef.current = questionInfo?.expectedMaxDuration;
                }
            } else {
                currentQuestionRef.current = null;
                setCurrentQuestionInfo(null);
                playIndexRef.current = playIndexRef.current + 1;
            }

            if (repeatClipRef.current?.status === audioPlayReasons.PLAYING) {
                repeatClipRef.current = {
                    status: audioPlayReasons.NOT_PLAYING,
                    counter: repeatClipRef.current?.counter,
                };
            }
        };
    };

    /*
        Once the content is received from api, this function calls the service to frame the question list
        for TT . All the part will be in one question list. Hard and medium is also fetched on load. 
        Later based on rating questions will be played. 
    */
    const frameQuestions = async (contentData: any) => {
        let {
            questionList = [],
            partIdx = {},
            hardQuestionList = [],
            mediumQuestionList = [],
        } = await frameTestQuestions(contentData);
        questionListRef.current = questionList;
        partIndexRef.current = partIdx;
        dynamicQuestListRef.current = { hardQuestionList, mediumQuestionList };
        currentQuestionRef.current = questionList[0];
        setCurrentQuestionInfo(questionList[0]);
    };

    /* 
        Fetches the probe list which includes probes for all parts
    */
    const fetchProbeData = async () => {
        let res = await getProbes(testTaker);
        return res;
    };

    /*
        Simple way to move to next question.
        Handles the silent and response interval timer
        Calls the stop recording with the reason
        Reason will be logged on array of object for future
    */
    const moveToNextQuestion = (reason: any) => {
        clearInterval(responseIntervalRef.current);
        if (silentTimeRef.current > 0)
            console.log(`Silent for ${silentTimeRef.current / 2} seconds`);
        stopRecording(reason);
    };

    /*
        Core feature of the test - Intent detection
        All the intent based rules were implemented here
        Sends the intent response as argument. Based on that all rules were implemented
        Rules were varied by intents
        Rules were varied by parts of the test

    */

    const checkForIntentDetectionRules = (event: any) => {
        let currentPart = currentPartRef.current;
        console.log('Intent', event);
        if (event === `${intentResponse.REPEAT}`) {
            if (currentPart !== Parts.PART_3) {
                repeatClip('Intent Received as repeat');
            } else {
                intentResponseRef.current = { status: audioPlayReasons.CAN_RESTART };
                playVariant('Intent Received as repeat');
            }
        } else if (event === `${intentResponse.MOVE_ON}`) {
            if (currentPart !== Parts.PART_2) {
                moveToNextQuestion('Intent Received as move on');
            }
        } else if (event === `${intentResponse.STUCK}`) {
            if (currentPart === Parts.PART_1) {
                moveToNextQuestion('Intent Received as stuck');
            }
            if (currentPart === Parts.PART_3) {
                intentResponseRef.current = { status: audioPlayReasons.CAN_RESTART };
                playVariant('Intent Received as stuck');
            }
        }
    };

    const updateResponseData = (data: any) => {
        let currentObj = playedAudioFilesArray.current[playedAudioFilesArray.current.length - 1];
        if (data.intent) {
            currentObj['intent'] = data.intent;
        }
        if (data.inputTranscript) {
            currentObj['transcript'] = data.inputTranscript;
        }
        if (data.nluConfidenceScore) {
            currentObj['confidenceScore'] = data.nluConfidenceScore;
        }
    };

    /* 
        Creates websocket connection  and event listeners will be added 
        On Message call the rule function for execution of rules
        On open just logs the message for verfication
        On Close logs the error object or websocket object , which tells the code, reason for close
    */
    const createWebSocket = () => {
        webSocketRef.current = connectToIntentAudioSocket(testTaker);
        webSocketRef.current?.addEventListener('message', (event: any) => {
            console.log('Message received from audio intent', event.data);
            try {
                let data = JSON.parse(JSON.parse(event?.data));
                updateResponseData(data);
                intentResponseRef.current = { status: audioPlayReasons.RECEIVED };
                checkForIntentDetectionRules(data.intent);
            } catch (err: any) {
                console.log('Error while parsing intent response', err);
            }
        });
        webSocketRef.current?.addEventListener('open', (event: any) => {
            console.log('Audio Web socket is opened');
        });
        webSocketRef.current?.addEventListener('error', (event: any) => {
            console.log('Audio Web socket is having  error');
        });
        webSocketRef.current?.addEventListener('close', (event: any) => {
            console.log('Audio Web socket is closed', event);
        });
    };

    /*
        Closes the socket if its open.
        Helps in closing the socket wherever needed
        No argument needed
    */
    const closeSocket = async () => {
        if (webSocketRef.current?.readyState === WebSocket.OPEN) {
            webSocketRef.current.close();
        }
    };

    /*
        Implement the logic for the TestScreen component here.
        All initialization will take place from here
        Content is getting fetched
        Probe data is getting fetched
        Web Socket is getting created
        Question list is getting created
    */
    const initialize = async () => {
        fullRecording?.startRecording();
        let contentData = await fetchContent();
        let probeData = await fetchProbeData();
        probeListRef.current = probeData?.Probes;
        frameQuestions(contentData);
        createWebSocket();
    };

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // full recording includes avatar and user audio. On end returns the blob
    useEffect(() => {
        if (fullRecording?.mediaRecorder) {
            audioContextRef.current = new AudioContext();
            destinationRef.current = audioContextRef.current.createMediaStreamDestination();
            const micSource = audioContextRef.current.createMediaStreamSource(
                fullRecording?.mediaRecorder?.stream,
            );
            micSource.connect(destinationRef.current);
            mediaRecorderRef.current = new MediaRecorder(destinationRef.current.stream);
            mediaRecorderRef.current.ondataavailable = (event: any) => {
                setFullRecordingBlob(event.data);
            };
            mediaRecorderRef.current.start();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullRecording?.mediaRecorder]);

    const playProbe = (probClip: any, callbackOnEnd: any, reason: string) => {
        /**
         * Animation's talking mode is used for both talking and encourage modes,
         * as encourage animation mode is not supported in current animation
         * so animation's encourage mode is removed
         */
        handleAnimationModes(animationModes.TALKING);
        const audio = new Audio(probClip?.audioPreSignUrl);
        audio.volume = 0.6;
        audio.crossOrigin = 'anonymous';
        const sourceNode = audioContextRef.current?.createMediaElementSource(audio);
        if (destinationRef.current) {
            sourceNode?.connect(destinationRef.current);
        }
        if (audioContextRef?.current) {
            sourceNode?.connect(audioContextRef?.current?.destination);
        }
        audio
            .play()
            .then(() => {
                isAudioPlaying = true;

                // Call to handle the played audio files
                handlePlayedAudioFiles(probClip, reason, 'probe');
            })
            .catch((error) => {
                isAudioPlaying = false;
                handleAnimationModes(animationModes.IDLE);
                console.log('Playing inside catch', error);
            });
        audio.onended = () => {
            isAudioPlaying = false;
            handleAnimationModes(animationModes.IDLE);
            callbackOnEnd();
        };
    };

    const repeatClip = (reason: any) => {
        if (repeatClipRef.current?.counter < RULE_FOUR_MAX_COUNTER) {
            console.log('Reason for repeat', reason);
            /*
                This condition is for pausing the intent detection recording if the user is silent for
                x seconds and repeat clip is played. Because amazon lex accepts upto 15 seconds of recording
                Duration of silence (5 sec) + repeat clip (2 sec) + again  prompt ( 5 sec )  + TT response ( 4) > 15 sec
                so websocket is getting closed. So decided to pause the recording between the avatar is talking
            */
            if (!currentQuestionRef?.current?.noIntentDetection && speakingTimeRef.current === 0) {
                partialRecorderRef.current?.stopRecording();
            }
            silentTimeRef.current = 0;
            speakingTimeRef.current = 0;
            repeatClipRef.current = {
                status: audioPlayReasons.PLAYING,
                counter: repeatClipRef.current?.counter + 1,
            };
            let probeClipInfo = probeListRef?.current?.find((ele: any) => ele.probeKey === 'pb2');
            if (probeClipInfo) {
                playProbe(
                    probeClipInfo,
                    () => {
                        currentQuestionRef.current = { ...currentQuestionRef.current };
                        setCurrentQuestionInfo({ ...currentQuestionRef.current });
                    },
                    reason,
                );
            }
        } else {
            moveToNextQuestion('Reached maximum repeat counter');
        }
    };

    /*
        Interupt clip is played once the test taker speaking at the expected maximum duration of the prompt
        The probe 1 is used for interupt clip
        Once the probe is played , moves to the next question
    */
    const interuptClip = (reason: any) => {
        console.log('Reason for interupt', reason);
        let probeClipInfo = probeListRef?.current?.find((ele: any) => ele.probeKey === 'pb1');
        if (probeClipInfo) {
            playProbe(
                probeClipInfo,
                () => {
                    currentQuestionRef.current = null;
                    setCurrentQuestionInfo(null);
                    if( partTimeRef.current <= 20 ) {
                        let nextPartIndex = Object.keys( partIndexRef.current ).find((e: any) => e >  playIndexRef.current )
                        playIndexRef.current = currentPartRef?.current !== Parts.PART_3 ? Number(nextPartIndex) - 1: questionListRef.current.length - 2;
                        clearInterval(partIntervalRef.current);
                    }
                    if (playIndexRef.current < questionListRef.current.length - 1) {
                        playIndexRef.current = playIndexRef.current + 1;
                    } else {
                        playIndexRef.current = playIndexRef.current + 1;
                        stopFullRecording();
                    }
                },
                reason,
            );
        }
    };

    /* 
        Encourage will be execute if the test taker responsed very short response
        Probes are listed based on parts
        Probes are played sequentially , once the sequence is matches to length of probe list, it will itterate
        For part 2 , only two probes will be played and after that it moved to next question
    */
    const encourage = (reason: any) => {
        let currentPart = currentPartRef.current;
        let probeIndex = probeRef?.current?.index;
        probeRef.current = {
            status: audioPlayReasons.PLAYING,
            index: probeIndex,
            count: probeRef.current?.count,
        };
        silentTimeRef.current = 0;
        let probeClipInfo = filteredProbeData[probeIndex];
        if (
            probeClipInfo &&
            ((currentPart === Parts.PART_2 && probeRef?.current?.count < 2) ||
                currentPart === Parts.PART_3)
        ) {
            playProbe(
                probeClipInfo,
                () => {
                    if (probeIndex === filteredProbeData?.length - 1) {
                        probeIndex = 2;
                    } else {
                        probeIndex += 1;
                    }
                    probeRef.current = {
                        status: audioPlayReasons.NOT_PLAYING,
                        index: probeIndex,
                        count: probeRef.current?.count + 1,
                    };
                    if (currentPartRef.current === Parts.PART_3) {
                        intentResponseRef.current = { status: audioPlayReasons.CAN_RESTART };
                        partialRecorderRef.current.startRecording();
                        speakingTimeRef.current = 0.5;
                    }
                },
                reason,
            );
        } else {
            probeRef.current = { status: audioPlayReasons.NOT_PLAYING, index: 2, count: 0 };
            stopRecording('Reached maximum probe');
        }
    };

    /*
        Check for rules function will execute all non intent rules
        Majorly there are 4 ref used to execte the rule. Itteration ref, Silent Ref, Speaking ref, Repeat ref
        Based on test progression algorithm rules were executed
        Only non -intent rules like rule 1,2,3,4 are executed from here
        Values were configured in constant.js If any values need to be changed, we can change it in constant file
    */
    const checkForRules = (isSilence: any) => {
        let maxDuration = currentQuestionRef.current?.expectedMaxDuration
            ? currentQuestionRef.current?.expectedMaxDuration * 1000
            : MAX_DURATION;
        let minDuration = currentQuestionRef.current?.expectedMinDuration;
        let currentPart = currentPartRef.current;
        // Encourage
        if (
            itterationCountRef.current < minDuration * 2 &&
            speakingTimeRef?.current / 2 >= 0.5 && // some response should be there
            silentTimeRef?.current / 2 > RULE_THREE_MIN_DURATION - 0.5 &&
            (currentQuestionRef.current?.noIntentDetection ||
                (!currentQuestionRef.current?.noIntentDetection &&
                    (intentResponseRef.current?.status === audioPlayReasons.RECEIVED ||
                        intentResponseRef.current?.status === audioPlayReasons.CAN_RESTART)))
        ) {
            encourage(audioPlayReasons.NOT_MUCH_RESPONDED);
        }
        // Move On
        if (
            itterationCountRef.current * 500 > maxDuration || // checks whether duration is less than maxDuration
            (isSilence &&
                itterationCountRef.current / 2 > minDuration &&
                speakingTimeRef?.current / 2 >= 0.5 &&
                silentTimeRef.current / 2 >
                    (currentPart === Parts.PART_2
                        ? RULE_TWO_PART_2_SILENCE_DURATION
                        : RULE_TWO_SILENCE_DURATION) -
                        0.5 &&
                (currentQuestionRef.current?.noIntentDetection ||
                    (!currentQuestionRef.current?.noIntentDetection &&
                        (intentResponseRef.current?.status === audioPlayReasons.RECEIVED ||
                            intentResponseRef.current?.status === audioPlayReasons.CAN_RESTART))))
        ) {
            moveToNextQuestion('On Voice end');
        }

        // Repeat
        if (
            repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING &&
            itterationCountRef.current * 500 < maxDuration &&
            speakingTimeRef.current === 0 &&
            silentTimeRef.current / 2 > RULE_ONE_SILENCE_DURATION - 0.5 &&
            variantRef?.current?.status === audioPlayReasons.NOT_PLAYING
        ) {
            if (currentPart !== Parts.PART_3) {
                repeatClip('No response');
            }
            if (currentPart === Parts.PART_3) {
                playVariant('No response');
            }
        }
    };

    /*
        This function determines when to stop the intent recording
        Once the intent recording is stopped, conversion and sending the blob will take place
        Once the user started talking, from that it will take 4 seconds of recording
        It will be executed, if the prompt is having intent detection enabled
    */
    const checkForIntentDetection = () => {
        const intentDetectionTime = INTENT_DETECTION_TIME ? INTENT_DETECTION_TIME * 2 : 8;
        if (
            speakingTimeRef.current > 0.5 &&
            !currentQuestionRef.current?.noIntentDetection &&
            (intentResponseRef.current?.status === audioPlayReasons?.NOT_STARTED ||
                intentResponseRef.current?.status === audioPlayReasons?.CAN_RESTART) &&
            (speakingTimeRef.current >= intentDetectionTime ||
                silentTimeRef.current >= intentDetectionTime - 1 ||
                speakingTimeRef.current + silentTimeRef.current >= intentDetectionTime - 1)
        ) {
            console.log('Intent detection clip is ready');
            intentResponseRef.current = { status: audioPlayReasons?.IN_PROGRESS };
            partialRecorderRef?.current?.stopRecording();
        }
    };
    /*
        This function act as heart of the application
        It is responsible for finding whether the user made some speech or not
        This fetch silence function is executed every 500 milli seconds.
        isSilenceDetectedForGivenTime() gives a boolean value . True - User is silent , False - User is speaking
        Once the value is fetched, it will call rules function
        Animations also handled here. Based on boolean value avatar will be in idle mode or listening mode
        If repeat probe and clip is repeated again then at that time this will not be executed 
    */
    useEffect(() => {
        // This will be executed on once user recording is started for the prompt
        if (audiRecorder2Ref?.current?.mediaRecorder) {
            const fetchSilence = async () => {
                // This interval results whether user is speaking or in silent mode for every 500 milli second to maxDuration
                responseIntervalRef.current = setInterval(async () => {
                    if (audiRecorder2Ref?.current?.mediaRecorder) {
                        itterationCountRef.current = itterationCountRef.current + 1;
                        if (
                            repeatClipRef.current?.status === audioPlayReasons.NOT_PLAYING &&
                            probeRef?.current?.status === audioPlayReasons.NOT_PLAYING &&
                            variantRef.current.status === audioPlayReasons.NOT_PLAYING
                        ) {
                            lastSilentDetectionRef.current = await isSilenceDetectedForGivenTime(
                                audiRecorder2Ref?.current,
                                500,
                                silenceMeanValue || 0.01544762491032353,
                            );
                            if (!lastSilentDetectionRef.current) {
                                handleAnimationModes(animationModes.LISTENING);
                                speakingTimeRef.current = speakingTimeRef.current + 1;
                                silentTimeRef.current = 0;
                            } else {
                                if (modeRef.current !== animationModes.TALKING && !isAudioPlaying) {
                                    handleAnimationModes(animationModes.IDLE);
                                }
                                silentTimeRef.current = silentTimeRef.current + 1;
                                // speakingTimeRef.current = 0; commenting this line if in case speaking time needs to be reset to 0 if silence
                            }
                        }
                        // passing silence value to function
                        checkForRules(lastSilentDetectionRef.current);
                        checkForIntentDetection();
                    }
                }, 500);
            };
            fetchSilence();
        }

        // This will execute ones the user recording is done for particular prompt and before the playing
        // the next audio
        if (audiRecorder2Ref?.current?.recordingBlob && !audiRecorder2Ref?.current?.mediaRecorder) {
             (async () => {
                const currentPlayed = playedAudioFilesArray.current.at(-1);
                let { wavBlob } = await convertWebmToWav(audiRecorder2Ref?.current?.recordingBlob as Blob);
                const response = await uploadAudioFile(wavBlob, ".wav", userAudioFileName, testTaker);
                Object.assign(currentPlayed, {
                    responseFilePath: response.data.filePath
                })
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audiRecorder2Ref?.current?.mediaRecorder]);

    return (
        <div className={classes.container}>
            {!loading ? (
                endPage ? (
                    <Card className={classes.endMessageCard}>
                        <Text text="You have finished you Speaking test." />
                    </Card>
                ) : cardTextRef.current ? (
                    <div className={classes.gridContainer}>
                        <Card className={classes.instructions}>
                            <Text text={cardTextRef.current} />
                        </Card>
                        {isMonologueQuestion.current && (
                            <div className={classes.timerProgressBar}>
                                <TimerProgressBar  timerBarDuration={timerBarDuration.current} />
                            </div>    
                        )}
                       
                        {showTimer ? (
                            <Card className={classes.timer}>
                                <Text
                                    text="Preparation time left:"
                                    variant="heading"
                                    size="md"
                                    fontWeight="bold"
                                />
                                <Text
                                    text={secondsToMinutes(timerRef.current)}
                                    variant="heading"
                                    size="xl"
                                    fontWeight="bold"
                                />
                            </Card>
                        ) : (
                            <>
                                <AvatarScene mode={modeRef.current} className={classes.sideAvatar} />
                                <RecordingBar mediaRecorder={audiRecorder2Ref.current.mediaRecorder} className={classes.sideRecordingBar} />
                            </>
                        )}
                    </div>
                ) : (
                    <div  className={classes.mainAvatarContainer}>
                        <AvatarScene mode={modeRef.current} className={classes.mainAvatar}/>
                        <RecordingBar mediaRecorder={audiRecorder2Ref.current.mediaRecorder} />
                    </div>
                )
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default TestScreen;
