/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card } from '../../components/Card';
import './MicrophoneSelection.css';
import { Button } from '../../components/Button';
import MicrophoneSelectionScreen from './MicrophoneSelectionScreen';
// Importing the necessary hooks for Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { changeAppMicAccess } from '../../redux/appConfigSlice';
import { Text } from '../../components/Text';
import SilenceCheckScreen from './SilenceCheckScreen';
import SpeakCheckScreen from './SpeakCheckScreen';
import FullNameScreen from './FullNameScreen';

// Display the proper error message while accessing the microphone.
const getErrorMessage = (errObj: any) => {
    if (errObj.name === 'NotAllowedError' || errObj.message === 'NotAllowedError') {
        return 'Microphone access denied by the user. Cannot procced with test';
    } else if (errObj.name === 'NotFoundError' || errObj.message === 'NotFoundError') {
        return 'No microphone device found.';
    } else {
        return 'Error accessing the microphone: ' + errObj?.errorMessage;
    }
};

/* Main component for the microphone selection screen */
export default function MicrophoneSelectionMain(props: any) {
    const { appMicAccess, selectedMic, silenceMeanValue } = useSelector((state: RootState) => state.appConfigSlice);
    const dispatch = useDispatch<AppDispatch>();

    const [micAccess, setMicAccess] = useState(appMicAccess);
    const [micAccessErrorMsg, setMicAccessErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [isTimerFinished, setIsTimerFinished] = useState<boolean>(false);
    const [isSpeakCheckFinished, setIsSpeakCheckFinished] = useState<boolean>(false);
    const [isFullNameFinished, setIsFullNameFinished] = useState<boolean>(false);

    /* Check whether user has provided permission for microphones */
    function checkMicrophoneAccess() {
        window.navigator?.mediaDevices
            ?.getUserMedia({ audio: true })
            .then(function (stream) {
                setMicAccess(true);
                dispatch(changeAppMicAccess(true));
                stream.getTracks().forEach((track) => track.stop());
            })
            .catch(function (err) {
                // Handle errors
                setLoading(false);
                dispatch(changeAppMicAccess(false));
                setMicAccessErrorMsg(err);
            });
    }

    useEffect(() => {
        if (!appMicAccess) {
            setLoading(true);
            checkMicrophoneAccess();
        }
        props.setNextButtonDisabled(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFullNameFinished) {
            props.setNextButtonDisabled(false);
        }
    }, [isFullNameFinished]);

    useEffect(() => {
        if (props.prevButtonClicked && !isTimerFinished) {
            props.setMicPrecheck(true);
        }
    }, [props.prevButtonClicked, isTimerFinished]);

    return (
        <div className="micBlock">
            {!isTimerFinished && !isFullNameFinished && (
                <>
                    <div
                        style={{
                            height: micAccess ? '152px' : 'auto',
                        }}
                    >
                        <Card className="mic-card">
                            {micAccess ? (
                                <MicrophoneSelectionScreen
                                    setNextButtonDisabled={props.setNextButtonDisabled}
                                    onClickNext={props.onClickNext}
                                    prevButtonClicked={props.prevButtonClicked}
                                    setPrevCallBackDone={props.setPrevCallBackDone}
                                />
                            ) : (
                                <MicrophoneErrorScreen
                                    errorMessage={micAccessErrorMsg}
                                    checkMicrophoneAccess={checkMicrophoneAccess}
                                    isMicAccessEnabled={appMicAccess}
                                    loading={loading}
                                />
                            )}
                        </Card>
                    </div>
                    <Card className="silence-check-card ">
                        <div className="micCard">
                            <SilenceCheckScreen
                                setIsTimerFinished={setIsTimerFinished}
                                selectedMic={selectedMic}
                                prevButtonClicked={props.prevButtonClicked}
                                setPrevCallBackDone={props.setPrevCallBackDone}
                            />
                        </div>
                    </Card>
                </>
            )}

            {isTimerFinished && !isSpeakCheckFinished ? (
                <Card className="speaking-testing-card">
                    <SpeakCheckScreen
                        silenceMeanValue={silenceMeanValue}
                        selectedMic={selectedMic}
                        setIsSpeakCheckFinished={setIsSpeakCheckFinished}
                        prevButtonClicked={props.prevButtonClicked}
                        setPrevCallBackDone={props.setPrevCallBackDone}
                        setIsTimerFinished={setIsTimerFinished}
                        setPrevButtonClicked={props.setPrevButtonClicked}
                    />
                </Card>
            ) : null}

            {isSpeakCheckFinished && !isFullNameFinished ? (
                <Card className="speaking-testing-card">
                    <FullNameScreen
                        silenceMeanValue={silenceMeanValue}
                        selectedMic={selectedMic}
                        setIsFullNameFinished={setIsFullNameFinished}
                        prevButtonClicked={props.prevButtonClicked}
                        setPrevCallBackDone={props.setPrevCallBackDone}
                        setIsTimerFinished={setIsTimerFinished}
                        setPrevButtonClicked={props.setPrevButtonClicked}
                        setIsSpeakCheckFinished={setIsSpeakCheckFinished}
                    />
                </Card>
            ) : null}

            {isFullNameFinished ? (
                <Card className="speaking-testing-card">
                    <p>
                        <Text text="Thank you. Your microphone is working fine." fontWeight="semibold" />
                    </p>
                    <Text text="Click <span class='bolderString'>Next</span> to move to the next part" fontWeight="semibold" />
                </Card>
            ) : (
                ''
            )}
        </div>
    );
}

/* Display an error message when the user denies microphone access */
export const MicrophoneErrorScreen = (props: any) => {
    const { isMicAccessEnabled, loading } = props;
    let returnDiv;
    if (loading) {
        returnDiv = (
            <div>
                <p>
                    <Text text="Please wait while we are checking your microphone access" fontWeight="semibold" />
                </p>
                <Button size="lg">Checking permission...</Button>
            </div>
        );
    }
    return (
        <div>
            {
                <div>
                    <p>
                        <Text text="Checking microphone access" variant="heading" fontWeight="semibold" />
                    </p>

                    {returnDiv}
                    {!loading && !isMicAccessEnabled ? (
                        <div>
                            <Text text="Please allow access to your microphone" fontWeight="semibold" />
                            <p>
                                <Text text="After providing access please refresh the page" />
                            </p>
                            {props.errorMessage && <Text text={getErrorMessage(props.errorMessage)} fontWeight="semibold" />}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            }
        </div>
    );
};
