export const formatDate = (date: any) => {
  let datePart = [date.getMonth() + 1, date.getDate(), date.getFullYear()]
    .map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0"))
    .join("-");
  let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map((n, i) => n.toString().padStart(2, "0"))
    .join(":");
  return datePart + "_" + timePart;
};

/**
 * @param date
 * @returns the date in dd-MMM-YYYY format eg: '24-Nov-2024
 */
export const getFormattedDate = (date: any) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getDate();
  const month = months[date.getMonth()]; // getMonth() returns 0-based month index
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/**
 * @returns the date time format example: 20241126123456 for date 2024-11-26 12:34:56
 */
export const getFormattedDateAndTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const updatePlayedAudioFilesArray = (
  playedAudioFilesArray: any[],
  playedAudioFileObj: {
    audioFileURL: string;
    audioFileRepeatCount: number;
    audioFilePlayDateTime: string;
  }
) => {
  // Check if the audio file URL already exists in the playedAudioFilesArray array
  // If not, push the new audio file info object to the array.
  // If yes, increment the audioFileRepeatCount by 1.
  const isURLPresent = !!playedAudioFilesArray.find(
    (obj: { audioFileURL: string }) =>
      obj.audioFileURL === playedAudioFileObj.audioFileURL
  );
  if (!isURLPresent) {
    playedAudioFilesArray = [...playedAudioFilesArray, playedAudioFileObj];
  } else {
    playedAudioFilesArray = playedAudioFilesArray.map(
      (obj: { audioFileRepeatCount: any; audioFileURL: string }) =>
        obj.audioFileURL === playedAudioFileObj.audioFileURL
          ? {...playedAudioFileObj, audioFileRepeatCount: playedAudioFileObj.audioFileRepeatCount + 1}
          : obj
    );
  }

  return playedAudioFilesArray;
};



// Helper function to encode WAV from an AudioBuffer
export const encodeToWavBuffer = (
  audioBuffer: AudioBuffer,
  numChannels = 1,
  targetSampleRate = 16000
) => {
  const length = audioBuffer.length * numChannels * 2 + 44; // 16-bit PCM + WAV header

  //creates an empty array buffer
  const wavBuffer = new ArrayBuffer(length);

  //Utlize DataView to help in populating the empty ArrayBuffer
  const view = new DataView(wavBuffer);

  //Inputting WAV file header
  writeString(view, 0, "RIFF"); //WAV file indicator
  view.setUint32(4, 36 + audioBuffer.length * numChannels * 2, true);
  writeString(view, 8, "WAVE"); //WAVE format indicator
  writeString(view, 12, "fmt "); //Start of format chunk
  view.setUint32(16, 16, true); //Format chunk size
  view.setUint16(20, 1, true); // Specifies PCM audio (uncompressed)
  view.setUint16(22, numChannels, true);
  view.setUint32(24, targetSampleRate, true);
  view.setUint32(28, targetSampleRate * numChannels * 2, true); //byte rate
  view.setUint16(32, numChannels * 2, true); //bytes per sample across all channels
  view.setUint16(34, 16, true); //bits per sample
  writeString(view, 36, "data"); //Start of data chunk
  view.setUint32(40, audioBuffer.length * numChannels * 2, true); //audio data size in bytes

  // Create a separate buffer for raw L16 data
  const l16Buffer = new Int16Array(audioBuffer.length * numChannels);

  //Inputting PCM audio data
  let offset = 44;
  for (let i = 0; i < audioBuffer.length; i++) {
    for (let channel = 0; channel < numChannels; channel++) {
      const sample = audioBuffer.getChannelData(channel)[i];
      const clampedSample = Math.max(-1, Math.min(1, sample));

      //convert to 16 bit integer
      const int16Sample =
        clampedSample < 0 ? clampedSample * 0x8000 : clampedSample * 0x7fff;

      // Add to WAV buffer
      view.setInt16(offset, int16Sample, true);
      offset += 2;

      // Add to L16 buffer
      l16Buffer[i * numChannels + channel] = int16Sample;
    }
  }

  return { wavBuffer, l16Buffer };
};

// Helper function to write strings to DataView
const writeString = (view: DataView, offset: number, string: string) => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

export const convertWebmToWav = async (
  webmBlob: Blob,
  numChannels = 1,
  targetSampleRate = 16000
) => {
  //Convert WebM blob as an ArrayBuffer
  const arrayBuffer = await webmBlob.arrayBuffer();

  // Decode the audio data using AudioContext
  const audioContext = new AudioContext({ sampleRate: targetSampleRate });
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Encode the audio buffer into a WAV file
  const { wavBuffer, l16Buffer } = encodeToWavBuffer(
    audioBuffer,
    numChannels,
    targetSampleRate
  );

  await audioContext.close()
  
  return {
    wavBlob: new Blob([wavBuffer], { type: "audio/wav" }),
    l16Blob: new Blob([l16Buffer], { type: "audio/l16" }),
  };
};



export const convertWavBlobToArrayBuffer = async(wavBlob: Blob): Promise<ArrayBuffer> => {
  try {
      const arrayBuffer = await wavBlob.arrayBuffer(); // Convert Blob to ArrayBuffer
      return arrayBuffer;
  } catch (error) {
      console.error("Error converting WAV Blob to ArrayBuffer:", error);
      throw error;
  }
}

/**
 * Function to create formatted date
 * @returns Speaking test formated date i.e. 2025-02-17T15:22:52.378761900+05:30[Asia/Calcutta]
 */
export const getSpeakingTestFormattedDate = () => {
  const date = new Date();
  // Get ISO format: "2025-02-17T15:22:52.378Z"
  const isoString = date.toISOString();
 
  // Get local timezone offset
  const offsetMinutes = date.getTimezoneOffset();
  const sign = offsetMinutes > 0 ? "-" : "+";
  const absOffsetMinutes = Math.abs(offsetMinutes);
  const hours = String(Math.floor(absOffsetMinutes / 60)).padStart(2, "0");
  const minutes = String(absOffsetMinutes % 60).padStart(2, "0");
  // Construct timezone offset in format "+05:30"
  const timezoneOffset = `${sign}${hours}:${minutes}`;
 
  // Get timezone name dynamically
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
  // Combine everything
  // 2025-02-17T15:22:52.378761900+05:30[Asia/Calcutta]
  return `${isoString.replace("Z", "")}${timezoneOffset}[${timeZone}]`;
}


/**
 * Formats a given timer value in seconds to a "MM:SS" string.
 *
 * @param timerValue - The timer value in seconds.
 * @returns The formatted time string.
 */
export const secondsToMinutes = (timerValue: number) => {
  const minutes = Math.floor(timerValue / 60);
  const seconds = Math.floor(timerValue % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}
