import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import { RadioButtonProps } from './RadioButton.types';
import classes from './RadioButton.module.css';
import { ErrorIcon } from '../../assets/icons';

export function RadioButton(props: PropsWithChildren<RadioButtonProps>) {
    const { children, className, disabled, helperText, error, ...rootProps } = props;

    const Element = typeof children === 'string'? 'span' : 'div';

    return (
        <label
            className={clsx(classes.root, {
                [classes.disabled]: disabled,
            })}
        >
            <input
                type="radio"
                className={clsx(classes.radio, className, { [classes.error]: error })}
                disabled={disabled}
                {...rootProps}
            />
            {children && <Element className={classes.description}>{children}</Element>}

            {children && helperText && (
                <Element className={clsx(classes.helperText, { [classes.error]: error })}>
                    {error && <ErrorIcon className={classes.helperIcon} data-testid="error-icon" />}
                    {helperText}
                </Element>
            )}
        </label>
    );
}
