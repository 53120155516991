import PersonalizationScreen from "./screens/PersonalizationScreen";
import "@fontsource-variable/mulish";
import "./App.css";
import "./common.css";

// Importing the necessary hooks for Redux
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import LoginScreen from "./screens/loginScreen/LoginScreen";
import { appThemeNames } from "./constants/constants";
import TestScreen from "./screens/testScreen/TestScreen";
import { Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "./screens/Routes/ProtectedRoutes";
import { FaroRoutes } from "@grafana/faro-react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

const App = () => {
  const navigate = useNavigate();
  const theme = document.documentElement.getAttribute("data-theme") ?? appThemeNames.BLACK_ON_WHITE;
  const appFontSize = useSelector(
    (state: RootState) => state.appConfigSlice.appFontSize
  );
  const appTheme = useSelector(
    (state: RootState) => state.appConfigSlice.appTheme
  );
  /**
   * Environment variable to determine whether the ILETS logo should be displayed or not in the application.
   * Which is managed by the the pipeline environment variable
   */
  const showILETSLogo = process.env.REACT_APP_SHOW_LOGO ?? "false";

  /**
   * UpdateFavicon is the function that changes the favicon icon for the browser tab.
   * @param iconPath is the path to the icon file to set to the browser tab icon.
   * which will be changed based on the current environment eg. DEV, QA, PROD etc .
   */
  const updateFavicon = (iconPath: string) => {
    const link: any = document.querySelector("link[rel='icon']");
    if (link) {
      link.href = iconPath;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = iconPath;
      document.head.appendChild(newLink);
    }
  };
  /**
   * Set favicon and title of the applcation tab based on the showILETSLogo boolean value.
   * Default react app logo is used in the development environment by default.
   * Note: Make sure the ILETSLogo.svg must be present in the public folder.
   */
  if (showILETSLogo === "true") {
    updateFavicon("/ILETSLogo.svg");
    document.title = "ICMST";
  }


  const setPrecheck = (isDone: boolean) => {
    if (isDone) {
      navigate('/test');
    }
  }


  return (
    <div className={`App`} data-theme={theme} id={theme}>
      <div className={`appBlock ${appTheme} ${appFontSize}`}>
        <FaroRoutes>
          <Route path="/" element={
            <>
              <Header />
              <div className={`centerBlock`}>
                <LoginScreen />
              </div>
              <Footer />
            </>
            }
          />
          <Route path="*" element={<ProtectedRoute />} />
          <Route path="/login" element={
            <>
              <Header />
              <div className={`centerBlock`}>
                <LoginScreen />
              </div>
              <Footer />
            </>
          } />
          <Route element={<ProtectedRoute />}>
            <Route path="/precheck" element={
              <>
                <Header />
                <PersonalizationScreen
                  setPreChecksDone={setPrecheck}
                />
                <Footer />
              </>
            } />
            <Route path="/test" element={
              <>
                <Header />
                <TestScreen />
                <Footer />
              </>
              } 
            />
          </Route>
        </FaroRoutes>
      </div>
    </div>
  );
};

export default App;