import * as React from 'react';

import type { InputProps, InternalInputProps } from './Input.types';
import classes from './Input.module.css';
import { Text } from '../Text';
import clsx from 'clsx';

export const Input = (props: InputProps) => {
    const { type, readonly, disabled, onChange, onClick, value, label, helperText, placeholder, error, icon, inputClassName } =
        props;
    const { hintText, children, onKeyDown } = props as InternalInputProps;
    return (
        <div className={classes.root}>
            {label && (
                <label>
                    <Text fontWeight="semibold" text={label} />
                </label>
            )}
            {hintText && (
                <div>
                    {' '}
                    <Text text={hintText} size="xs" />{' '}
                </div>
            )}
            <div className={classes['dropdown-wrapper']}>
                <div className={classes['input-wrapper']}>
                    <input
                        data-testid="input-test-id"
                        autoComplete="off"
                        type={type}
                        readOnly={readonly}
                        disabled={disabled}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder}
                        className={clsx(
                            {
                                [classes.error]: error,
                            },
                            inputClassName
                        )}
                        onKeyDown={onKeyDown}
                        onClick={onClick}
                    />
                    {icon && <div className={classes.icon}>{icon}</div>}
                </div>
                {children}
            </div>

            {helperText && (
                <div>
                    <Text text={helperText} size="xs" className={clsx({ [classes.error]: error })} />
                </div>
            )}
        </div>
    );
};

Input.displayName = 'Input';

export const DropdownInput = (props: InternalInputProps) => {
    return <Input {...props} />;
};
