import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Navigate, Outlet } from 'react-router-dom';


const ProtectedRoute = () => {
    const testTakerAccessToken = useSelector(
        (state: RootState) => state.appConfigSlice.testTaker.testTakerAccessToken
    );

    if (!testTakerAccessToken) {
        return <Navigate to={'/'} />;
    }

    return <Outlet />;
}

export default ProtectedRoute;