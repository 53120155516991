import _ from 'lodash';
import { decryptURL } from "./DecryptionService";

export const frameTestQuestions = async ( contentData:any ) => {
    let questionList: any = [];
    let partIdx: any = {}
    let hardQuestionList: any = [];
    let mediumQuestionList: any = [];
    let part1QuestionList: any = frameQuestionsForPart1(contentData?.part1And2Res?.partOne)
    if (part1QuestionList?.length > 0) {
        questionList = [...part1QuestionList]
        partIdx = { 0: "part1"};
    }
    let part2QuestionList: any = frameQuestionsForPart2(contentData?.part1And2Res?.partTwo)
    if (part2QuestionList?.length > 0) {
        questionList = [...questionList, ...part2QuestionList];
        partIdx[(questionList.length - part2QuestionList?.length)] = "part2";
    }
    let returnedList:any  = frameQuestionsForPart3(contentData?.part3Res?.partThree)
    let part3QuestionList = returnedList[0];
    hardQuestionList = returnedList[1];
    mediumQuestionList = returnedList[2]
    if (part3QuestionList?.length > 0) {
        questionList = [...questionList, ...part3QuestionList];
        partIdx[(questionList.length - part3QuestionList?.length)] = "part3";
    }

    const nonUniqQuestionList = [...questionList, ...hardQuestionList, ...mediumQuestionList ]
    const uniqQuestionList =_.uniqBy(nonUniqQuestionList, ({ localizedMedia }) => localizedMedia.audioPreSignUrl)

    for (const question of uniqQuestionList) {
        try {
        const encryptedAudioUrl = question?.localizedMedia.audioPreSignUrl;
        
        question.localizedMedia.audioPreSignUrl = await decryptURL(encryptedAudioUrl);
        if( question?.variants?.length > 0 ) {
            for (const variants of question.variants ) {
                variants.localizedMedia.audioPreSignUrl = await decryptURL(variants?.localizedMedia.audioPreSignUrl);
            }
        }
        } catch (error) {
            console.error("Error decrypting Content URL:", error);
        }
    }
  
    return { questionList, partIdx , hardQuestionList,  mediumQuestionList }
}

// frame question for part 1
const frameQuestionsForPart1 = (partData: any) => {
    let questionList: any = [];
    if (partData?.introFrame?.introStatement) {
        questionList?.push({ ...partData?.introFrame?.introStatement, noIntentDetection: true })
    }
    if (partData?.introFrame?.prompts) {
        partData?.introFrame?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        questionList = partData?.introFrame?.prompts
            ? [...questionList, ...partData.introFrame.prompts.map((prompt: any) => ({...prompt, noIntentDetection: true }))] 
            : questionList;
    }
    if (partData?.introFrame?.outroStatement) {
        questionList?.push(partData?.introFrame?.outroStatement)
    }
    if (partData?.frameA?.prompts) {
        partData?.frameA?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        questionList = partData?.frameA?.prompts 
        ? [...questionList, ...partData.frameA.prompts.map((prompt: any) => ({...prompt, noIntentDetection: false }))] 
        : questionList;
    }
    if (partData?.frameB?.prompts) {
        partData?.frameB?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        questionList = partData?.frameB?.prompts ? [...questionList, ...partData.frameB.prompts] : questionList;
    }
    if (partData?.frameC?.prompts) {
        partData?.frameC?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        questionList = partData?.frameC?.prompts ? [...questionList, ...partData.frameC.prompts] : questionList;
    }
    return questionList;
}

// frame question for part 2
const frameQuestionsForPart2 = (partData: any) => {
    let questionList: any = [];
    if (partData?.introStatement) {
        questionList?.push(partData?.introStatement)
    }
    if (partData?.frameD?.cardStatement) {
        questionList?.push({ ...partData?.frameD?.cardStatement, showTimer: true })
    }
    if (partData?.frameD?.bridgeStatement) {
        questionList?.push({ ...partData?.frameD?.bridgeStatement, })
    }
    if (partData?.frameD?.prompts) {
        partData?.frameD?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        partData.frameD.prompts[0] = { ...partData.frameD.prompts[0], monologueQuestion: true }
        questionList = partData?.frameD?.prompts ? [...questionList, ...partData.frameD.prompts] : questionList;
    }
    return questionList;
}

// frame question for part 3
const frameQuestionsForPart3 = (partData: any) => {
    let questionList: any = [];
    let hardQuestionList:any = [];
    let mediumQuestionList:any = [];
    if (partData?.introStatement) {
        questionList?.push(partData?.introStatement)
    }
    if (partData?.frameE?.prompts) {
        partData?.frameE?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        questionList = partData?.frameE?.prompts ? [...questionList, ...partData.frameE.prompts] : questionList;
    }
    if (partData?.frameF?.prompts) {
        partData?.frameF?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        mediumQuestionList = partData?.frameF?.prompts ? [ ...partData.frameF.prompts] : mediumQuestionList;
        mediumQuestionList.push(partData?.outroStatement)
    }
    if (partData?.frameG?.prompts) {
        partData?.frameG?.prompts?.sort((a: any, b: any) => a.sequence < b.sequence ? -1 : 1);
        hardQuestionList = partData?.frameG?.prompts ? [ ...partData.frameG.prompts] : hardQuestionList;
        hardQuestionList.push(partData?.outroStatement)
    }
    return [ questionList, hardQuestionList, mediumQuestionList ] ;
    
}