import { LiveAudioVisualizer as LiveAudioVisualizerLib } from 'react-audio-visualize';
import clsx from 'clsx';

import type { LiveAudioVisualizerProps } from './LiveAudioVisualizer.types';
import classes from './LiveAudioVisualizer.module.css';
import useComputedStyle from '../../hooks/useComputedStyle';

export const LiveAudioVisualizer = (props: LiveAudioVisualizerProps) => {
    const { mediaRecorder, width = 115, height = 20, barColor, className } = props;

    const { elementRef, computedStyles } = useComputedStyle();

    if (!mediaRecorder) return null;

    return (
        <div className={clsx(classes.root, className)}>
            <span ref={elementRef} className={classes.colorReference}>
                theme color reference
            </span>
            <LiveAudioVisualizerLib
                mediaRecorder={mediaRecorder}
                width={width}
                height={height}
                gap={3}
                barColor={barColor || computedStyles.color}
            />
        </div>
    );
};

LiveAudioVisualizer.displayName = 'LiveAudioVisualizer';
