import {  useState, useCallback } from 'react';

const useComputedStyle = () => {
    const [computedStyles, setComputedStyles] = useState<CSSStyleDeclaration>(
        {} as CSSStyleDeclaration,
    );

    const elementRef = useCallback((node: HTMLElement | null) => {
        if (node) {
            setComputedStyles(getComputedStyle(node))
        }  
    }, []);

    return { elementRef, computedStyles };
};

export default useComputedStyle;
