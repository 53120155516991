import type { TimeProgressProps } from './TimeProgress.types';
import classes from './TimeProgress.module.css'
import { Card } from '../Card';
import { Text } from '../../components/Text';

/**
 * @param props of type TimeProgressProps
 * @returns the progress bar component with time bar progress in %, start time and end time
 */
export const TimeProgress = (props: TimeProgressProps) => { 
    const {
        showTime,
        progress,
        startTime,
        endTime
    } = props;

    return (
        <div className={showTime ? classes.SoundTimerGrid : ""}>
            {showTime && 
                <div className={classes.centerTime}>
                    <Text text={`${startTime}`} variant="body" fontWeight="semibold" />
                </div>
            }
            <Card className={showTime ? `${classes.timerBarCard} ${classes.soundTimerCard} ${classes.noMargin}` : `${classes.timerBarCard}`} >
                <div className={classes.timerProgressBg}>
                    <div className={classes.progressBarColor} data-testid="progress-bar" 
                        style={{
                        width: `${progress}%`,
                        transition: progress === 100 ? "none" : "width 0.1s linear",
                        }}
                    />
                </div>
            </Card>
            {showTime && 
                <div className={classes.centerTime}>
                    <Text text={`${endTime}`} variant="body" fontWeight="semibold" />
                </div>
            }
        </div>
    );
};

TimeProgress.displayName = 'TimeProgress';
