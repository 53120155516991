import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import type { CardProps } from './Card.types';
import classes from './Card.module.css';

export const Card = (props: PropsWithChildren<CardProps>) => {
    const { className, children } = props;

    return <div className={clsx(classes.root, className)}>{children}</div>;
};

Card.displayName = 'Card';
