import  { useRef, useState, useEffect } from "react";

const AudioPlayer = (src: string) => {
  const audioRef = useRef<HTMLAudioElement>(new Audio(src));
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
//   const [volume, setVolume] = useState(1)  --commented line

  useEffect(() => {
    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
      console.log("Audio playing is ENDED.");
    };
    const handleLoadedMetadata = () => {
      if(audio){
        setDuration(Math.round(audio.duration));
      }
      console.log("Audio exact duration: ", audio.duration)
    }
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  const playAudio = () => {
    audioRef.current.play();
    setIsPlaying(true);
    console.log("Audio playing is STARTED.");
  };
  const audioRoundedDuration = () => {
    return isNaN(duration) ? 0 : duration;
  }

  return {
    playAudio,
    // pauseAudio,
    // stopAudio,
    // changeVolume,
    isPlaying,
    // volume,
    // audioRef,
    audioRoundedDuration
  };
};
export default AudioPlayer;
