import { RadioButtonGroup, RadioButtonGroupProps } from '../../components/RadioButtonGroup';
import { Text } from '../../components/Text';

// Importing the necessary hooks for Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';

// Redux imports for theme change
import { changeAppTheme } from '../../redux/appConfigSlice';

import { appThemeNames } from '../../constants/constants';

// Implement contrast selection screen. Implement radio buttons for each contrast mode.
const ContrastSelection = () => {
    const contrastMode = useSelector((state: RootState) => state.appConfigSlice.appTheme);
    const dispatch = useDispatch();

    const radioButtonOptions: RadioButtonGroupProps['options'] = [
        {
            value: appThemeNames.BLACK_ON_WHITE,
            label: <Text text="Black on white" fontWeight="semibold" variant="heading" />,
            helperText:  <Text text="Choose this mode to see how your screen will look" />,
        },
        {
            value: appThemeNames.WHITE_ON_BLACK,
            label: <Text text="White on black" fontWeight="semibold" variant="heading" />,
            helperText: <Text text="Choose this mode to see how your screen will look" />,
        },
        {
            value: appThemeNames.YELLOW_ON_BLACK,
            label: <Text text="Yellow on black" fontWeight="semibold" variant="heading" />,
            helperText: <Text text="Choose this mode to see how your screen will look" />,
        },
    ];

    return (
        <div className="contrast-selection-container">
            <RadioButtonGroup
                name={'contrastMode'}
                onChange={(e) => {
                    dispatch(changeAppTheme(e.target.value));
                    document.documentElement.setAttribute('data-theme', e.target.value);
                }}
                options={radioButtonOptions}
                value={contrastMode}
            />
        </div>
    );
};
export default ContrastSelection;
