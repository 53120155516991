import React, { useState } from "react";
import  './Image.css';
import { ImageProps } from '../../interface/interface';

// Image Component to display images
/**
 * 
 * @param props 
 * @returns Image of the SourceImage
 * allowHovering is boolean optional param in props, if it is true then it is allowing the mouse hovering
 * and on mouse hovering image opacity will be 60%
 */
const Image = (props: ImageProps) => {
    const { SourceImage= null } = props;
    const [isHovering, setIsHovering] = useState(false);
    return(
        <div role="img" className={`imageBlock ${isHovering ? 'imageHoverOpacity' : ''} ${props.className ?? ''} ${props.imageName}`} onClick={props.onClick} 
            onMouseEnter={()=> { if(props.allowHovering) setIsHovering(true) } } 
            onMouseLeave={()=> { if(props.allowHovering) setIsHovering(false) } }
        >
            {SourceImage? <SourceImage /> : ''}
        </div>
    )
}

export default Image;