import { RadioButtonGroup, RadioButtonGroupProps } from '../../components/RadioButtonGroup';
import { Text } from '../../components/Text';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { changeAppFontSize } from '../../redux/appConfigSlice';
import { FontSizeType } from '../../interface/interface';

// Font selection screen component with radio buttons to change font size.
const FontSelection = () => {
    const fontSize = useSelector((state: RootState) => state.appConfigSlice.appFontSize);
    const dispatch = useDispatch();

    const radioButtonOptions: RadioButtonGroupProps['options'] = [
        {
            value: 'sm',
            label: (
                <Text text="Small text size " fontWeight="semibold" variant="heading" size="sm" />
            ),
            helperText: (
                <Text text="This is a sample text size that includes the number 1234" size="sm" />
            ),
        },
        {
            value: 'md',
            label: (
                <Text text="Medium text size " fontWeight="semibold" variant="heading" size="md" />
            ),
            helperText: (
                <Text text="This is a sample text size that includes the number 1234" size="md" />
            ),
        },
        {
            value: 'lg',
            label: (
                <Text text="Large text size " fontWeight="semibold" variant="heading" size="lg" />
            ),
            helperText: (
                <Text text="This is a sample text size that includes the number 1234" size="lg" />
            ),
        },
    ];

    return (
        <div className="font-selection-container">
            <RadioButtonGroup
                name="fontSize"
                onChange={(e) => {
                    dispatch(changeAppFontSize(e.target.value as FontSizeType));
                }}
                options={radioButtonOptions}
                value={fontSize}
            />
        </div>
    );
};

export default FontSelection;
