import { DropdownItemProps, InternalDropdownItemProps } from './Dropdown.types';
import classes from './Dropdown.module.css';
import { isEnter, isSpaceBar } from '../ComponentUtils/keyboardUtils';

export const DropdownItem = (props: DropdownItemProps) => {
    const { onSelect, label, value } = props;
    const { isLast, index } = props as InternalDropdownItemProps;

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (onSelect) {
            onSelect(value);
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (isEnter(e) || isSpaceBar(e)) {
            if (onSelect) {
                onSelect(value);
            }
        }
    };

    return (
        <div
            data-testid={`dropdown-item-${index}`}
            role="menuitem"
            className={classes['dropdown-item']}
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
            style={{
                borderBottom: isLast ? '0' : undefined,
            }}
        >
            {label}
        </div>
    );
};
