import clsx from 'clsx';

import { HeaderProps } from './Header.types';
import classes from './Header.module.css';
import { IELTSLogoIcon } from '../../assets/icons';
import { Text } from '../Text/Text';

export const Header = (props: HeaderProps) => {
    const { className } = props;

    return (
        <nav className={clsx(classes.root, className)}>
            <IELTSLogoIcon className={classes.logo} data-testid="header-logo" />
            <Text
                text="Speaking Test"
                variant="heading"
                fontWeight="semibold"
                className={classes.text}
            />
        </nav>
    );
};

Header.displayName = 'Header';
