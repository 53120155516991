import clsx from 'clsx';
import _ from 'lodash';

import { RadioButton } from '../RadioButton/RadioButton';
import type { RadioButtonGroupProps } from './RadioButtonGroup.types';
import { Card } from '../Card';
import classes from './RadioButtonGroup.module.css';

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
    const { name, options, value, onChange, className } = props;

    return (
        <div className={clsx(classes.root, className)}>
            {options.map((option, idx) => {
                const isChecked = _.isNil(value) ? undefined : value === option.value;

                return (
                    <Card key={idx}>
                        <RadioButton
                            name={name}
                            value={option.value}
                            checked={isChecked}
                            disabled={option.disabled}
                            error={option.error}
                            helperText={option.helperText}
                            onChange={onChange}
                        >
                            {option.label}
                        </RadioButton>
                    </Card>
                );
            })}
        </div>
    );
};

RadioButtonGroup.displayName = 'RadioButtonGroup';
