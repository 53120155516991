import { SVGAttributes } from 'react';

export const ErrorIcon = ({ className, ...rootProps }: SVGAttributes<SVGSVGElement>) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        {...rootProps}
    >
        <path
            d="M9.58203 13.3327C9.58203 12.8724 9.95513 12.4994 10.4154 12.4994C10.8756 12.4994 11.2487 12.8724 11.2487 13.3327C11.2487 13.7929 10.8756 14.166 10.4154 14.166C9.95513 14.166 9.58203 13.7929 9.58203 13.3327ZM9.58203 6.66602C9.58203 6.20578 9.95513 5.83268 10.4154 5.83268C10.8756 5.83268 11.2487 6.20578 11.2487 6.66602V10.8327C11.2487 11.2929 10.8756 11.666 10.4154 11.666C9.95513 11.666 9.58203 11.2929 9.58203 10.8327V6.66602ZM10.407 1.66602C5.80703 1.66602 2.08203 5.39935 2.08203 9.99935C2.08203 14.5994 5.80703 18.3327 10.407 18.3327C15.0154 18.3327 18.7487 14.5994 18.7487 9.99935C18.7487 5.39935 15.0154 1.66602 10.407 1.66602ZM10.4154 16.666C6.73203 16.666 3.7487 13.6827 3.7487 9.99935C3.7487 6.31602 6.73203 3.33268 10.4154 3.33268C14.0987 3.33268 17.082 6.31602 17.082 9.99935C17.082 13.6827 14.0987 16.666 10.4154 16.666Z"
            fill="currentColor"
        />
    </svg>
);

ErrorIcon.displayName = 'ErrorIcon';
