import clsx from 'clsx';

import classes from './LoadingSpinner.module.css';
import type { LoadingSpinnerProps } from './LoadingSpinner.types';

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
    const { className, ...rootProps } = props;

    return (
        <svg
            {...rootProps}
            className={clsx(classes.root, className)}
            width="24"
            height="25"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 4.75C11 4.19772 11.4477 3.75 12 3.75C16.9706 3.75 21 7.77944 21 12.75C21 17.7206 16.9706 21.75 12 21.75C7.02944 21.75 3 17.7206 3 12.75C3 12.1977 3.44772 11.75 4 11.75C4.55228 11.75 5 12.1977 5 12.75C5 16.616 8.13401 19.75 12 19.75C15.866 19.75 19 16.616 19 12.75C19 8.88401 15.866 5.75 12 5.75C11.4477 5.75 11 5.30228 11 4.75Z"
                fill="curentColor"
            />
        </svg>
    );
};

LoadingSpinner.displayName = 'LoadingSpinner';
