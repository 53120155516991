import { getHeader, postHeader, uploadAudioFileDetails } from "./apiOptions";
import { fetchData, postData } from "./apiService";
import { apiUrl, REACT_APP_WEBSOCKET_URL } from "../constants/constants";
import { getFormattedDate, getSpeakingTestFormattedDate } from "../service/utils";
import { decryptURL } from "../service/DecryptionService";

// Get data from the API using the getHeader function
export const getData = async () => {
  try {
    const data = await fetchData(apiUrl.GET_P1_QUESTIONS, { headers: getHeader() }, "cmst", {});
    console.log("Fetched data:", data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Post data to the API using the postHeader function
export const uploadAudioFile = async (fileBlob: Blob, fileFormat: string, fileName: string, testTakerDetails: any) => {
  try {
    let data = uploadAudioFileDetails(fileBlob, fileFormat || ".mp3", fileName || "", testTakerDetails);
    return await postData(
      apiUrl.UPLOAD_AUDIO_FILE, data,
      { headers: { "Authorization": 'Bearer ' + testTakerDetails.testTakerAccessToken } },
      "cmst"
    );
  } catch (error) {
    console.error("Error posting data:", error);
  }
};

export const getContentData = async (testTakerDetails: any) => {
  try {
    const response = await postData(
      apiUrl.GET_CONTENT_DATA,
      {
        "testTakerId": testTakerDetails?.testTakerId,
        "centreName": testTakerDetails?.testTakerCenterName,
        "langAccent": testTakerDetails?.testTakerLanguage,
        "testTakerDate": getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken } },
      "atc"
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching content data:", error);
    throw error;
  }
}

export const getPart3ContentData = async (testTakerDetails: any) => {
  try {
    const response = await postData(
      apiUrl.GET_P3_QUESTIONS,
      {
        "testTakerId": testTakerDetails?.testTakerId,
        "centreName": testTakerDetails?.testTakerCenterName,
        "langAccent": testTakerDetails?.testTakerLanguage,
        "testTakerDate": getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId,
        "ratingScore": "NA"
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken } },
      "atc"
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching content data:", error);
  }
}

export const getProbes = async (testTakerDetails: any) => {
  try {
    const response = await postData(
      apiUrl.GET_PROBES,
      {
        "testTakerId": testTakerDetails?.testTakerId,
        "centreName": testTakerDetails?.testTakerCenterName,
        "langAccent": testTakerDetails?.testTakerLanguage,
        "testTakerDate": getFormattedDate(new Date()),
        "uniqueSessionId": testTakerDetails?.testTakerSessionId
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken } },
      "atc"
    );

    // Decrypt URLs and attach to the items
    const probesData = response.data;
    const probes = probesData.Probes;

    for (const probe of probes) {
      probe.audioPreSignUrl = await decryptURL(probe.audioPreSignUrl);
    }

    return probesData;
  } catch (error) {
    console.error("Error fetching probe data:", error);
  }
}

export const fetchTestTakerSessionId = async ( testTakerAccessToken: any, username: any ) => {
  /**
   * Passsing username as a query param to get unique session id
   */
  try {
    return await fetchData(apiUrl.GET_SESSION_ID_URL + "?loginInUser=" + username, { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerAccessToken } }, "cmst", {});
  } catch (error) {
    console.error("Error fetching fetchTestTakerSessionId:", error);
  }
}


export const connectToIntentAudioSocket = (testTaker: any) => {
  let socket = new WebSocket(`${REACT_APP_WEBSOCKET_URL}${apiUrl.INTENT_AUDIO_DETECTION}`,
    // ["Authorization", testTaker?.testTakerAccessToken]
  );
  return socket;
}


export const getRating = () => {
  /* This part will be replaced once we integrated LEX. Till that we have to fetch Medium type
  in Part 3 as per story 859. Once lex is integarted, score will be generated through api. It will return 
  value between 0-10 .  if we receive less than 5,  medium will be played . If it is greater than or equal to
  5 , we will play  hard type question in  part 3. As of now to play medium hardcoded as 4*/
  let ratingScore = 6;
  return ratingScore
}
export const saveSpeakingTestProgressionData = async (testTakerDetails: any = undefined, playedAudioFilesDetails: any = [], status: string = "Test Completed", fullRecordingFilePath: string = "") => {
  try {
    const response = await postData(
      apiUrl.SPEAKING_TEST_PROGRESSION_DATA,
      {
        "testTakerId": testTakerDetails?.testTakerId,
        "uniqueSessionId": testTakerDetails?.testTakerSessionId,
        "centerName": testTakerDetails?.testTakerCenterName,
        "testDate": getSpeakingTestFormattedDate(),
        "language": testTakerDetails?.testTakerLanguage,
        "status": status,
        "playedAudioFilesDetails": playedAudioFilesDetails,
        "fullAudioRecordingFilePath": fullRecordingFilePath
      },
      { headers: { ...getHeader(), "Authorization": 'Bearer ' + testTakerDetails?.testTakerAccessToken } },
      "cmst"
    );
    return response?.data;
  } catch (error) {
    console.error("Error while saving progression test data:", error);
    throw error;
  }
}


export const fetchHealthCheck = async () => {
  try {
    return {
      service: await fetchData(apiUrl.HEALTH_CHECK, { headers: { ...getHeader() } }, 'cmst'),
      atc: await fetchData(apiUrl.HEALTH_CHECK, { headers: { ...getHeader() } }, 'atc')
    };
  } catch (error) {
    console.error("Error Health Check")
  }
}
