import React from 'react';
import type { TextProps } from './Text.types';
import classes from './Text.module.css';
import clsx from 'clsx';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

export const Text = (props: TextProps) => {
    const appFontSize = useSelector((state: RootState) => state.appConfigSlice.appFontSize);

    const getFontSizeClass = React.useMemo(() => {
        if (props.size) {
            return classes[`${props.variant === 'heading' ? 'heading' : 'body'}-${props.size}`];
        }

        return classes[`${props.variant === 'heading' ? 'heading' : 'body'}-${appFontSize}`];
    }, [appFontSize, props.size, props.variant]);

    return (
        <span
            data-testid="text-cmp-id"
            dangerouslySetInnerHTML={{ __html: props.text }}
            className={clsx(
                classes.root,
                classes[props.variant ? props.variant : 'body'],
                getFontSizeClass,
                classes[props.fontWeight ? props.fontWeight : 'regular'],
                classes[props.type ? props.type : ''],
                {
                    [classes.italic]: props.italic,
                    [classes.underlined]: props.underlined,
                    [classes.disabled]: props.disabled,
                },
                props.className
            )}
        ></span>
    );
};

Text.displayName = 'Text';
