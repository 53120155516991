import { useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { useGLTF, useAnimations, Stage } from '@react-three/drei';
import * as THREE from 'three';

import { AvatarSceneProps } from '../AvatarScene.types';

export const Avatar = (props: AvatarSceneProps) => {
    const { mode } = props;
    // 1) Load the GLTF model and animations
    const { scene, animations } = useGLTF('/Sam24.glb');
    const { actions } = useAnimations(animations, scene);

    // 2) Access R3F’s renderer for tone mapping, etc.
    const { gl } = useThree();

    // 3) On mount, set tone mapping & exposure
    useEffect(() => {
        gl.toneMapping = THREE.ACESFilmicToneMapping;
        gl.toneMappingExposure = 0.7;
    }, [gl]);

    // 4) Manage the lights
    useEffect(() => {
        // Ambient light
        const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
        scene.add(ambientLight);

        // A spotlight for additional highlight
        const spotLight = new THREE.SpotLight(0xffffff, 3);
        spotLight.position.set(-0.75, 0.18, 0.18);
        spotLight.angle = Math.PI / 6;
        spotLight.penumbra = 0.5;
        spotLight.decay = 2;
        spotLight.castShadow = false;
        scene.add(spotLight);

        return () => {
            scene.remove(ambientLight);
            scene.remove(spotLight);
        };
    }, [scene]);

    // 5) Animation logic
    useEffect(() => {
        if (!actions) return;

        const idleAction = actions['IDLE'];
        const talkAction = actions['TALKING'];
        const listenAction = actions['LISTENING'];
        const encourageAction = actions['ENCOURAGING'];

        function playAnimation(
            newAnim?: THREE.AnimationAction,
            fadeOutAnims?: (THREE.AnimationAction | null)[],
        ) {
            if (!newAnim) return;
              newAnim.reset()?.fadeIn(0.5).play();
              fadeOutAnims?.forEach((anim) => anim?.fadeOut(0.5));
        }

        switch (mode) {
            case 'IDLE':
                playAnimation(idleAction!, [talkAction, listenAction, encourageAction]);
                break;
            case 'TALKING':
                playAnimation(talkAction!, [idleAction, listenAction, encourageAction]);
                break;
            case 'LISTENING':
                playAnimation(listenAction!, [idleAction, talkAction, encourageAction]);
                break;
            case 'ENCOURAGING':
                playAnimation(encourageAction!, [idleAction, talkAction, listenAction]);
                break;
            default:
                console.warn('Unknown animation mode:', mode);
        }
    }, [actions, mode]);

    // 6) Configure shadow casting
    useEffect(() => {
        scene.traverse((obj) => {
            if ((obj as THREE.Mesh).isMesh) {
                const mesh = obj as THREE.Mesh;
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                // Example ignoring certain submeshes
                if (mesh.name.includes('C_glasses_lenses_MESH')) {
                    mesh.castShadow = false;
                    mesh.receiveShadow = false;
                }
                if (mesh.name.includes('Cyclo')) {
                    mesh.castShadow = false;
                }
            }
        });
    }, [scene]);

    return (
        <>
            <primitive object={scene} />
            <Stage environment={{ files: '/venice_sunset_1k.hdr' }} adjustCamera={false} />
            <CameraSetup />
        </>
    );
};

const CameraSetup = () => {
    const { camera } = useThree();

    useEffect(() => {
        camera.position.set(0, 0, 1); // Sets camera position
        camera.lookAt(0, 0, 0); // Camera looks at the center (origin)
    }, [camera]);

    return null;
};

Avatar.displayName = 'Avatar';
