import { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { AudioRecorderDefaultConfig } from '../../components/audioRecorder/AudioRecorder';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { LiveAudioVisualizer } from '../../components/LiveAudioVisualizer';
import { uploadAudioFile } from '../../api/authApis';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { isSilenceDetectedForGivenTime } from '../../service/MicService';
import { convertWebmToWav } from '../../service/utils';
import { Text } from '../../components/Text';
import useComputedStyle from '../../hooks/useComputedStyle';

const FullNameScreen = (props: any) => {
    const testTakerDetails = useSelector((state: RootState) => state.appConfigSlice.testTaker);
    const [isRecordingStarted, setIsRecordingStarted] = useState(false);
    const [errorScreen, setErrorScreen] = useState(false);
    const [uploadBlob, setUploadBlob] = useState(false);
    const { elementRef, computedStyles } = useComputedStyle();

    let audioRecorder = useAudioRecorder({
        ...AudioRecorderDefaultConfig,
        deviceId: props?.selectedMic || 'default',
    });

    const stop = async () => {
        audioRecorder.stopRecording();
    };

    useEffect(() => {
        if (props.prevButtonClicked) {
            stop();
            props.setIsTimerFinished(false);
            props.setIsFullNameFinished(false);
            props.setIsSpeakCheckFinished(false);
            props.setPrevButtonClicked(false);
        }

        return () => {
            props.setIsTimerFinished(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prevButtonClicked]);

    useEffect(() => {
        if (audioRecorder?.recordingBlob && uploadBlob) {
            (async () => {
                let { wavBlob } = await convertWebmToWav(audioRecorder.recordingBlob as Blob);
                let fileFormat = '.wav';
                let fileName = 'fullname';
                uploadAudioFile(wavBlob, fileFormat, fileName, testTakerDetails);
                props?.setIsFullNameFinished(true);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.recordingBlob]);

    useEffect(() => {
        if (!isRecordingStarted && audioRecorder?.recordingTime > 0) {
            audioRecorder?.stopRecording();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordingStarted]);

    useEffect(() => {
        const fetchData = async () => {
            if (audioRecorder?.mediaRecorder) {
                let output = await isSilenceDetectedForGivenTime(
                    audioRecorder,
                    3000,
                    props?.silenceMeanValue,
                );
                if (!output) {
                    setUploadBlob(true);
                    onStop();
                } else {
                    setErrorScreen(true);
                    setUploadBlob(false);
                    onStop();
                }
                console.log('output: ', output);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder?.mediaRecorder]);

    const onStart = async () => {
        setIsRecordingStarted(true);
        audioRecorder?.startRecording();
    };

    const onStop = async () => {
        setIsRecordingStarted(false);
    };

    return (
        <>
            {errorScreen ? (
                <div>
                    <p>
                        <Text text="We can't hear you." fontWeight="semibold" />
                    </p>
                    <p>
                        <Text
                            text="This may be because you are speaking too quietly, or because there is too much noise around you."
                            fontWeight="semibold"
                        />
                    </p>
                    <Text text="Please move closer to the microphone or try speaking more loudly." fontWeight="semibold" />
                    <div className="buttonBlk">
                        <Button onClick={() => setErrorScreen(false)}>Try Again</Button>
                    </div>
                </div>
            ) : (
                <div>
                    <p>
                        <Text text="We need to do a final check." fontWeight="semibold" />
                    </p>
                    <Text text=" Click <span class='bolderString'>Start</span>, then say your full name." fontWeight="semibold" />
                    <div className="buttonBlk">
                        <Button size="lg" onClick={onStart} ref={elementRef}>
                            {isRecordingStarted ? (
                                <LiveAudioVisualizer
                                    mediaRecorder={audioRecorder?.mediaRecorder as MediaRecorder}
                                    barColor={computedStyles.color}
                                    height={13}
                                />
                            ) : (
                                'Start'
                            )}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default FullNameScreen;
